import UiDetail from "./UiDetail";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumPipe } from "@/models/EnumPipe";
import DtoDetailPriceQuotationSheet_Company from "./DtoDetailPriceQuotationSheet_Company";
import DtoDetailPriceQuotationSheet_Cargo from "./DtoDetailPriceQuotationSheet_Cargo";
import { EnumPriceQuotationSheetStage, EnumPriceQuotationSheetStageOption } from "./EnumPriceQuotationSheetStage";
import DtoDetailPriceQuotationSheet_Pool from "./DtoDetailPriceQuotationSheet_Pool";
import DtoDetailPriceQuotationSheet_PriceQuotationSheetFile from "./DtoDetailPriceQuotationSheet_PriceQuotationSheetFile";
import DtoDetailPriceQuotationSheet_Scheme from "./DtoDetailPriceQuotationSheet_Scheme";
import DtoDetailPriceQuotationSheet_TrackRecord from "./DtoDetailPriceQuotationSheet_TrackRecord";
import UiGroup from "./UiGroup";

/**
 * 报价表详细
 * @description 自动生成的代码,请勿修改
 */
export default class DtoDetailPriceQuotationSheet extends UiDetail<string> {
    constructor() {
        //无参构造函数
        super()
        this.Cargos = [];
        this.Stage = EnumPriceQuotationSheetStage.Find;
        this.Files = [];
        this.Schemes = [];
        this.TrackRecords = [];
        this.UiGroups = [{"Code":"BasicInfo","Label":"\u57FA\u7840\u4FE1\u606F","Toggle":true,"Close":false,"Buttons":[]},{"Code":"ReviewDescription","Label":"\u590D\u76D8\u63CF\u8FF0","Toggle":true,"Close":false,"Buttons":[]},{"Code":"TrackRecord","Label":"\u8FFD\u8E2A\u8BB0\u5F55","Toggle":true,"Close":false,"Buttons":[]}];
    }

    [proName: string]: any;
    /**
     * 编号
     */
    Code?: string;
    /**
     * 报价人
     */
    CreatedCompanyUserId?: string;
    /**
     * 客户企业Id
     */
    CompanyId?: string;
    /**
     * 客户企业
     */
    Company?: DtoDetailPriceQuotationSheet_Company;
    /**
     * 联系人Id
     */
    UserId?: string;
    /**
     * 客户企业
     */
    CompanyLabel?: string;
    /**
     * 询盘人
     */
    UserLabel?: string;
    /**
     * 创建人姓名
     */
    CreatedUserName?: string;
    /**
     * 创建人手机号码
     */
    CreatedPhoneNumber?: string;
    /**
     * 货物数据
     */
    Cargos?: DtoDetailPriceQuotationSheet_Cargo[];
    /**
     * 货物数据
     */
    Weight?: number;
    /**
     * 毛重
     */
    Volume?: number;
    /**
     * 装运数量
     */
    PackagesNumber?: number;
    /**
     * 装运包装
     */
    PackagesKind?: string;
    /**
     * 阶段
     */
    Stage?: EnumPriceQuotationSheetStage;
    /**
     * 委托书
     */
    Pool?: DtoDetailPriceQuotationSheet_Pool;
    /**
     * Booking No
     */
    BookingNo?: string;
    /**
     * 附件
     */
    Files?: DtoDetailPriceQuotationSheet_PriceQuotationSheetFile[];
    /**
     * 询盘描述
     */
    InquiryDescription?: string;
    /**
     * 复盘描述
     */
    Schemes?: DtoDetailPriceQuotationSheet_Scheme[];
    /**
     * 追踪记录
     */
    TrackRecords?: DtoDetailPriceQuotationSheet_TrackRecord[];
}

/**
 * 报价表详细-表单校验
 */
export const DtoDetailPriceQuotationSheetRules = {
}

/**
 * 报价表详细-设置项
 */
export const DtoDetailPriceQuotationSheetMiezzDetailItems: MiezzFormItem[] = [
  {
    Prop: "Code",
    Label: "编号",
    Group: "BasicInfo",
    Span: 8,
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "CreatedCompanyUserId",
    Label: "报价人",
    Group: "BasicInfo",
    Span: 8,
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "CompanyLabel",
    Label: "客户企业",
    Group: "BasicInfo",
    Span: 8,
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "UserLabel",
    Label: "询盘人",
    Group: "BasicInfo",
    Span: 8,
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Cargos",
    Label: "货物数据",
    Group: "BasicInfo",
    Span: 8,
    Customize: true,
  },
  {
    Prop: "Stage",
    Label: "阶段",
    Group: "BasicInfo",
    Span: 8,
    Type: EnumMiezzFormItemType.Text,
    Pipe: EnumPipe.Enum,
    Options: EnumPriceQuotationSheetStageOption,
  },
  {
    Prop: "BookingNo",
    Label: "Booking No",
    Group: "BasicInfo",
    Span: 8,
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Files",
    Label: "附件",
    Group: "BasicInfo",
    Span: 8,
    Customize: true,
  },
  {
    Prop: "InquiryDescription",
    Label: "询盘描述",
    Group: "BasicInfo",
    Type: EnumMiezzFormItemType.Text,
  },
  {
    Prop: "Schemes",
    Label: "复盘描述",
    Group: "ReviewDescription",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "TrackRecords",
    Label: "追踪记录",
    Group: "TrackRecord",
    Span: 24,
    Customize: true,
  },
]
