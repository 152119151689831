
import MiezzMonthChoose from "@/components/MiezzMonthChoose.vue";
import moment from "moment";
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { init, EChartsOption } from "echarts";
import DtoRequestOrderPoolTrunkChart, {
  DtoRequestOrderPoolTrunkChartDynamicSearchItems,
} from "@/models.machine/DtoRequestOrderPoolTrunkChart";
import { EnumOrderPoolTrunkChartGroupBy } from "@/models.machine/EnumOrderPoolTrunkChartGroupBy";
import UiSelectOption from "@/models.machine/UiSelectOption";
import { EnumTransportDirectionOption } from "@/models.machine/EnumTransportDirection";
import { EnumTransportTypeOption } from "@/models.machine/EnumTransportType";
import { EnumTransportContainerLoadOption } from "@/models.machine/EnumTransportContainerLoad";
import { EnumOrderPoolTrunkChartDataSource } from "@/models.machine/EnumOrderPoolTrunkChartDataSource";
import OrderPoolTrunkAppService from "@/apis.machine/OrderPoolTrunkAppService";
import DynamicSearchAppService from "@/apis.machine/DynamicSearchAppService";
import { EnumOrderServiceType } from "@/models.machine/EnumOrderServiceType";
@Options({
  components: {
    MiezzMonthChoose,
  },
})
export default class CountTrunk extends Vue {
  types: {
    code?: string;
    title: string;
    type: "success" | "info";
  }[] = [
    {
      code: "CountTotalNumber",
      title: "票数统计",
      type: "success",
    },
    {
      code: "CountTotalNumber",
      title: "总计",
      type: "info",
    },
    {
      code: "CountCustomerNumber",
      title: "客户",
      type: "info",
    },
    {
      code: "CountSupplierNumber",
      title: "供应商",
      type: "info",
    },
    {
      code: "CountCustomNumber",
      title: "自定义",
      type: "info",
    },
    {
      code: "CountTotalGoods",
      title: "货量统计",
      type: "success",
    },
    {
      code: "CountTotalGoods",
      title: "总计",
      type: "info",
    },
    {
      code: "CountCustomerGoods",
      title: "客户",
      type: "info",
    },
    {
      code: "CountSupplierGoods",
      title: "供应商",
      type: "info",
    },
    {
      code: "CountCustomGoods",
      title: "自定义",
      type: "info",
    },
  ];
  type?: string = "";
  month?: string = "";
  tab?: string = "Number";

  companyId?: string;
  companies: UiSelectOption<string>[] = [];
  supplierId?: string;

  suppliers?: UiSelectOption<string>[] = [];
  dto = new DtoRequestOrderPoolTrunkChart();
  searchs = DtoRequestOrderPoolTrunkChartDynamicSearchItems;
  histories: UiSelectOption<string>[] = [];

  EnumTransportDirectionOption = EnumTransportDirectionOption;
  EnumTransportTypeOption = EnumTransportTypeOption;
  EnumTransportContainerLoadOption = EnumTransportContainerLoadOption;
  serviceType = EnumOrderServiceType.DeparturePort;
  EnumOrderServiceTypeOption = [
    { Label: "装货港", Value: 10, Data: "DeparturePort" },
    { Label: "目的港", Value: 20, Data: "DestinationPort" },
  ];

  containerTypes: UiSelectOption<string>[] = [];
  areas: UiSelectOption<string>[] = [];
  loadingPorts: UiSelectOption<string>[] = [];
  deliveryPorts: UiSelectOption<string>[] = [];

  created(): void {
    this.type = this.$route.query.type as string;
    if (!this.type) {
      this.type = this.types.filter((it) => it.code).firstOrDefault()?.code;
    }
    this.handleChooseType(this.type);

    OrderPoolTrunkAppService.GetClients().then((r) => {
      this.companies = r.data.Data ?? [];
      this.companyId = this.companies.firstOrDefault()?.Value;
    });
    OrderPoolTrunkAppService.GetSuppliers().then((r) => {
      this.suppliers = r.data.Data ?? [];
      this.suppliers.push({
        Value: "00000000-0000-0000-0000-000000000000",
        Label: "未选择供应商"
      })
      this.supplierId = this.suppliers.firstOrDefault()?.Value;
    });
    OrderPoolTrunkAppService.GetAreas().then((r) => {
      this.areas = r.data.Data ?? [];
    });
    OrderPoolTrunkAppService.GetLoadingPorts().then((r) => {
      this.loadingPorts = r.data.Data ?? [];
    });
    OrderPoolTrunkAppService.GetDeliveryPorts().then((r) => {
      this.deliveryPorts = r.data.Data ?? [];
    });
    this.handleGetHistories();
  }

  handleGetHistories(): void {
    DynamicSearchAppService.Get({
      Code: "Count_Pool",
    }).then((r) => {
      this.histories = r.data.Data ?? [];
    });
  }

  handleChooseType(type?: string): void {
    if (this.type != type) {
      this.type = type;
      this.tab = "Number";
      this.$route.query["type"] = this.type ?? "";
      this.$route.query["serviceType"] = this.serviceType.toString();
      this.$router.push({
        path: this.$route.path,
        params: this.$route.params,
        query: this.$route.query,
        force: true,
      });
      this.handleChart();
    }
  }

  handleChooseServiceType(): void {
    this.tab = "Number";
    this.$route.query["type"] = this.type ?? "";
    this.$route.query["serviceType"] = this.serviceType.toString();
    this.$router.push({
      path: this.$route.path,
      params: this.$route.params,
      query: this.$route.query,
      force: true,
    });
    this.handleChart();
  }

  @Watch("month")
  onMonthChanged(nv: any, ov: any): void {
    if (nv != ov) {
      this.handleChart();
    }
  }

  handleChart(): void {
    if (this.type && this.month) {
      switch (this.type) {
        case "CountTotalNumber":
          this.getChartBar("CountTotalNumber", "票数总计", {
            Month: this.month,
            GroupBy: EnumOrderPoolTrunkChartGroupBy.BusinessCycle,
            DataSource: EnumOrderPoolTrunkChartDataSource.Number,
          });
          break;
        case "CountCustomerNumber":
          this.getChartPie("CountCustomerNumberPie", "客户组成", {
            Month: this.month,
            GroupBy: EnumOrderPoolTrunkChartGroupBy.ClientCompany,
            DataSource: EnumOrderPoolTrunkChartDataSource.Number,
          });
          this.getChartBar("CountCustomerNumberBar", "客户组成", {
            Month: this.month,
            ClientCompanyId: this.companyId,
            GroupBy: EnumOrderPoolTrunkChartGroupBy.BusinessCycle,
            DataSource: EnumOrderPoolTrunkChartDataSource.Number,
          });
          break;
        case "CountSupplierNumber":
          this.getChartPie("CountSupplierNumberPie", "供应商组成", {
            Month: this.month,
            GroupBy: EnumOrderPoolTrunkChartGroupBy.SupplierCompany,
            DataSource: EnumOrderPoolTrunkChartDataSource.Number,
          });
          this.getChartBar("CountSupplierNumberBar", "供应商组成", {
            Month: this.month,
            SupplierCompanyId: this.supplierId,
            GroupBy: EnumOrderPoolTrunkChartGroupBy.BusinessCycle,
            DataSource: EnumOrderPoolTrunkChartDataSource.Number,
          });
          break;
        case "CountCustomNumber":
          this.dto.Month = this.month;
          this.dto.GroupBy = EnumOrderPoolTrunkChartGroupBy.BusinessCycle;
          this.dto.DataSource = EnumOrderPoolTrunkChartDataSource.Number;
          this.getChartBar("CountCustomNumber", "票数总计", this.dto);
          break;
        case "CountTotalGoods":
          this.getChartBar("CountTotalGoodsWeightBar", "毛重KGS", {
            Month: this.month,
            GroupBy: EnumOrderPoolTrunkChartGroupBy.BusinessCycle,
            DataSource: EnumOrderPoolTrunkChartDataSource.Weight,
          });
          this.getChartBar("CountTotalGoodsVolumeBar", "体积CBM", {
            Month: this.month,
            GroupBy: EnumOrderPoolTrunkChartGroupBy.BusinessCycle,
            DataSource: EnumOrderPoolTrunkChartDataSource.Volume,
          });
          break;
        case "CountCustomerGoods":
          this.getChartPie("CountCustomerGoodsWeightPie", "毛重KGS", {
            Month: this.month,
            GroupBy: EnumOrderPoolTrunkChartGroupBy.ClientCompany,
            DataSource: EnumOrderPoolTrunkChartDataSource.Weight,
          });
          this.getChartPie("CountCustomerGoodsVolumePie", "体积CBM", {
            Month: this.month,
            GroupBy: EnumOrderPoolTrunkChartGroupBy.ClientCompany,
            DataSource: EnumOrderPoolTrunkChartDataSource.Volume,
          });
          this.getChartBar("CountCustomerGoodsWeightBar", "毛重KGS", {
            Month: this.month,
            ClientCompanyId: this.companyId,
            GroupBy: EnumOrderPoolTrunkChartGroupBy.BusinessCycle,
            DataSource: EnumOrderPoolTrunkChartDataSource.Weight,
          });
          this.getChartBar("CountCustomerGoodsVolumeBar", "体积CBM", {
            Month: this.month,
            ClientCompanyId: this.companyId,
            GroupBy: EnumOrderPoolTrunkChartGroupBy.BusinessCycle,
            DataSource: EnumOrderPoolTrunkChartDataSource.Volume,
          });
          break;
        case "CountSupplierGoods":
          this.getChartPie("CountSupplierGoodsWeightPie", "毛重KGS", {
            Month: this.month,
            GroupBy: EnumOrderPoolTrunkChartGroupBy.SupplierCompany,
            DataSource: EnumOrderPoolTrunkChartDataSource.Weight,
          });
          this.getChartPie("CountSupplierGoodsVolumePie", "体积CBM", {
            Month: this.month,
            GroupBy: EnumOrderPoolTrunkChartGroupBy.SupplierCompany,
            DataSource: EnumOrderPoolTrunkChartDataSource.Volume,
          });
          this.getChartBar("CountSupplierGoodsWeightBar", "毛重KGS", {
            Month: this.month,
            SupplierCompanyId: this.supplierId,
            GroupBy: EnumOrderPoolTrunkChartGroupBy.BusinessCycle,
            DataSource: EnumOrderPoolTrunkChartDataSource.Weight,
          });
          this.getChartBar("CountSupplierGoodsVolumeBar", "体积CBM", {
            Month: this.month,
            SupplierCompanyId: this.supplierId,
            GroupBy: EnumOrderPoolTrunkChartGroupBy.BusinessCycle,
            DataSource: EnumOrderPoolTrunkChartDataSource.Volume,
          });
          break;
        case "CountCustomGoods":
          this.dto.Month = this.month;
          this.dto.GroupBy = EnumOrderPoolTrunkChartGroupBy.BusinessCycle;
          this.dto.DataSource = EnumOrderPoolTrunkChartDataSource.Weight;
          this.getChartBar("CountCustomGoodsWeightBar", "毛重KGS", this.dto);
          var dto = JSON.copy(this.dto) as DtoRequestOrderPoolTrunkChart;
          dto.DataSource = EnumOrderPoolTrunkChartDataSource.Volume;
          this.getChartBar("CountCustomGoodsVolumeBar", "体积CBM", dto);
          break;
        default:
          break;
      }
    }
  }

  getChartBar(
    ref: string,
    title: string,
    dto: DtoRequestOrderPoolTrunkChart
  ): void {
    dto.ServiceType = this.serviceType;
    OrderPoolTrunkAppService.GetChartBar(dto).then((r) => {
      const option: EChartsOption = {
        title: {
          text: `${title} x ${r.data.Data?.Total}`,
          left: "center",
          textStyle: {
            color: "#666",
          },
        },
        xAxis: {
          type: "category",
          data: r.data.Data?.XAxisData,
          axisLabel: { interval: 0 },
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: r.data.Data?.SeriesData,
            type: "bar",
            label: {
              show: true,
              position: "top",
            },
            barMaxWidth: 70
          },
        ],
      };
      this.initChart(ref, option);
    });
  }

  getChartPie(
    ref: string,
    title: string,
    dto: DtoRequestOrderPoolTrunkChart
  ): void {
    dto.ServiceType = this.serviceType;
    OrderPoolTrunkAppService.GetChartPie(dto).then((r) => {
      const option: EChartsOption = {
        title: {
          text: `${title} x ${r.data.Data?.Total}`,
          left: "center",
          textStyle: {
            color: "#666",
          },
        },
        tooltip: {
          trigger: "item",
        },
        legend: {
          orient: "vertical",
          left: "left",
        },
        series: [
          {
            type: "pie",
            radius: "50%",
            label: {
              show: false,
            },
            data: r.data.Data?.SeriesData,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
      this.initChart(ref, option);
    });
  }

  initChart(ref: string, option: EChartsOption): void {
    this.$nextTick(() => {
      const element = this.$refs[ref] as InstanceType<typeof HTMLElement>;
      if (element) {
        const echarts = init(element);
        echarts.setOption(option);
      }
    });
  }
}
