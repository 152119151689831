import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b4feb294"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_miezz_menu_tab = _resolveComponent("miezz-menu-tab")!
  const _component_miezz_select = _resolveComponent("miezz-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_miezz_input = _resolveComponent("miezz-input")!
  const _component_form_schemes = _resolveComponent("form-schemes")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_miezz_page_list = _resolveComponent("miezz-page-list")!
  const _component_miezz_button = _resolveComponent("miezz-button")!
  const _component_miezz_form = _resolveComponent("miezz-form")!
  const _component_miezz_button_bar = _resolveComponent("miezz-button-bar")!
  const _component_miezz_modal = _resolveComponent("miezz-modal")!
  const _component_form_cargos_modal = _resolveComponent("form-cargos-modal")!
  const _component_miezz_upload = _resolveComponent("miezz-upload")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_miezz_menu_tab, {
      modelValue: _ctx.menuTab,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.menuTab) = $event))
    }, null, 8, ["modelValue"]),
    _createVNode(_component_miezz_form, {
      ref: "miezz-form",
      modelValue: _ctx.form,
      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.form) = $event))
    }, {
      "form-item": _withCtx(({ item }) => [
        (item.Prop == 'CreatedCompanyUserId')
          ? (_openBlock(), _createBlock(_component_el_form_item, {
              key: 0,
              prop: item.Prop,
              label: item.Label
            }, {
              default: _withCtx(() => [
                _createVNode(_component_miezz_select, {
                  modelValue: _ctx.form.Data.CreatedCompanyUserId,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.Data.CreatedCompanyUserId) = $event)),
                  options: _ctx.createUsers,
                  clearable: false
                }, null, 8, ["modelValue", "options"])
              ]),
              _: 2
            }, 1032, ["prop", "label"]))
          : (item.Prop == 'CompanyLabel')
            ? (_openBlock(), _createBlock(_component_el_form_item, {
                key: 1,
                prop: item.Prop,
                label: item.Label,
                rules: _ctx.rules.CompanyLabel
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_miezz_input, {
                    ref: "ref-company-label",
                    modelValue: _ctx.form.Data.CompanyLabel,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.Data.CompanyLabel) = $event)),
                    "fetch-suggestions": _ctx.getCompanies,
                    "trigger-on-focus": true,
                    clearable: "",
                    valueKey: "Name",
                    onHandleSelect: _ctx.handleSelectCompany,
                    onHandleChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.handleSelectCompany())),
                    disabled: item.Disabled
                  }, null, 8, ["modelValue", "fetch-suggestions", "onHandleSelect", "disabled"])
                ]),
                _: 2
              }, 1032, ["prop", "label", "rules"]))
            : (item.Prop == 'UserLabel')
              ? (_openBlock(), _createBlock(_component_el_form_item, {
                  key: 2,
                  prop: item.Prop,
                  label: item.Label
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_miezz_input, {
                      modelValue: _ctx.form.Data.UserLabel,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.Data.UserLabel) = $event)),
                      "fetch-suggestions": _ctx.getUsers,
                      "trigger-on-focus": true,
                      clearable: "",
                      valueKey: "Label",
                      onHandleSelect: _ctx.handleSelectUser,
                      onHandleChange: _ctx.handleSelectUser,
                      disabled: item.Disabled || !_ctx.form.Data.CompanyLabel
                    }, null, 8, ["modelValue", "fetch-suggestions", "onHandleSelect", "onHandleChange", "disabled"])
                  ]),
                  _: 2
                }, 1032, ["prop", "label"]))
              : (item.Prop == 'Cargos')
                ? (_openBlock(), _createBlock(_component_el_form_item, {
                    key: 3,
                    prop: item.Prop,
                    label: item.Label
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("a", {
                        href: "javascript:void",
                        onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.handleEditCargos && _ctx.handleEditCargos(...args)))
                      }, [
                        (
              _ctx.form.Data.PackagesNumber ||
              _ctx.form.Data.PackagesKind ||
              _ctx.form.Data.Weight ||
              _ctx.form.Data.Volume
            )
                          ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.form.Data.PackagesNumber && _ctx.form.Data.PackagesKind
                ? `${_ctx.form.Data.PackagesNumber}${_ctx.form.Data.PackagesKind}`
                : _ctx.form.Data.PackagesNumber
                ? _ctx.form.Data.PackagesNumber
                : _ctx.form.Data.PackagesKind
                ? _ctx.form.Data.PackagesKind
                : "暂无数据") + "/ " + _toDisplayString(_ctx.form.Data.Weight ? `${_ctx.form.Data.Weight}KGS` : "暂无数据") + "/ " + _toDisplayString(_ctx.form.Data.Volume ? `${_ctx.form.Data.Volume}CBM` : "暂无数据"), 1))
                          : (_openBlock(), _createElementBlock("span", _hoisted_2, "暂无数据"))
                      ])
                    ]),
                    _: 2
                  }, 1032, ["prop", "label"]))
                : (item.Prop == 'Files')
                  ? (_openBlock(), _createBlock(_component_el_form_item, {
                      key: 4,
                      prop: item.Prop,
                      label: item.Label
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("a", {
                          href: "javascript:void",
                          onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.handleEditFiles && _ctx.handleEditFiles(...args)))
                        }, _toDisplayString((_ctx.form.Data.Files?.length ?? 0) > 0 ? "查看详情" : "暂无数据"), 1)
                      ]),
                      _: 2
                    }, 1032, ["prop", "label"]))
                  : (item.Prop == 'Schemes')
                    ? (_openBlock(), _createBlock(_component_el_form_item, {
                        key: 5,
                        prop: item.Prop,
                        labelWidth: "0px"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_form_schemes, {
                            ref: "ref-form-schemes",
                            form: _ctx.form,
                            "onUpdate:form": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.form) = $event)),
                            powers: _ctx.powers,
                            serviceRemarks: _ctx.serviceRemarks,
                            costs: _ctx.costs,
                            currencySystems: _ctx.currencySystems,
                            units: _ctx.units,
                            infoCollectors: _ctx.infoCollectors,
                            suppliers: _ctx.suppliers
                          }, null, 8, ["form", "powers", "serviceRemarks", "costs", "currencySystems", "units", "infoCollectors", "suppliers"])
                        ]),
                        _: 2
                      }, 1032, ["prop"]))
                    : (item.Prop == 'TrackRecords')
                      ? (_openBlock(), _createBlock(_component_el_form_item, {
                          key: 6,
                          prop: item.Prop,
                          labelWidth: "0px"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_miezz_page_list, {
                              modelValue: _ctx.listTrack,
                              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.listTrack) = $event)),
                              onHandleRowButtonClick: _ctx.handleRowButtonClick
                            }, {
                              column: _withCtx(({ model, row, index }) => [
                                (index == -1)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_3))
                                  : (model.Prop == 'Date')
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                                        _createVNode(_component_el_form_item, {
                                          prop: `TrackRecords[${index}].Date`,
                                          rules: _ctx.rules.Date,
                                          style: {"margin-bottom":"13px"}
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_el_date_picker, {
                                              modelValue: row.Date,
                                              "onUpdate:modelValue": ($event: any) => ((row.Date) = $event),
                                              type: "date",
                                              format: "YYYY/MM/DD",
                                              "value-format": "YYYY/MM/DD",
                                              class: "form-item-date"
                                            }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                          ]),
                                          _: 2
                                        }, 1032, ["prop", "rules"])
                                      ]))
                                    : (model.Prop == 'Content')
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                                          _createVNode(_component_el_form_item, {
                                            prop: `TrackRecords[${index}].Content`,
                                            rules: _ctx.rules.Content,
                                            style: {"margin-bottom":"13px"}
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_miezz_input, {
                                                modelValue: row.Content,
                                                "onUpdate:modelValue": ($event: any) => ((row.Content) = $event),
                                                type: "textarea",
                                                rows: 2,
                                                trim: false
                                              }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                            ]),
                                            _: 2
                                          }, 1032, ["prop", "rules"])
                                        ]))
                                      : _createCommentVNode("", true)
                              ]),
                              _: 1
                            }, 8, ["modelValue", "onHandleRowButtonClick"]),
                            _createVNode(_component_miezz_button, {
                              model: _ctx.addTrackBtn,
                              onHandleClick: _ctx.handleClick
                            }, null, 8, ["model", "onHandleClick"])
                          ]),
                          _: 2
                        }, 1032, ["prop"]))
                      : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_miezz_button_bar, {
      modelValue: _ctx.buttons,
      "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.buttons) = $event)),
      onHandleClick: _ctx.handleClick
    }, null, 8, ["modelValue", "onHandleClick"]),
    _createVNode(_component_miezz_modal, {
      modelValue: _ctx.modalImport,
      "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.modalImport) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_miezz_page_list, {
          modelValue: _ctx.listImport,
          "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.listImport) = $event))
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_form_cargos_modal, {
      ref: "modal-cargos",
      modelValue: _ctx.form,
      "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.form) = $event))
    }, null, 8, ["modelValue"]),
    _createVNode(_component_miezz_modal, {
      modelValue: _ctx.modalFile,
      "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.modalFile) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_miezz_form, {
          modelValue: _ctx.formFile,
          "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.formFile) = $event)),
          ref: "ref-form-file"
        }, {
          "form-item": _withCtx(({ item }) => [
            (item.Prop == 'Files')
              ? (_openBlock(), _createBlock(_component_el_form_item, {
                  key: 0,
                  label: item.Label,
                  prop: item.Prop
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_miezz_upload, {
                      modelValue: _ctx.formFile.Data.Files,
                      "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.formFile.Data.Files) = $event)),
                      path: "price/quotation-sheet",
                      prop: "File",
                      maxSize: 2 * 1024 * 1024
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 2
                }, 1032, ["label", "prop"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}