import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import DtoFormOrderWarehouse_OrderWarehouseAnnex from "./DtoFormOrderWarehouse_OrderWarehouseAnnex";
import DtoFormOrderWarehouse_OrderWarehousePackage from "./DtoFormOrderWarehouse_OrderWarehousePackage";
import { EnumWarehouseType, EnumWarehouseTypeOption } from "./EnumWarehouseType";
import { EnumOrderWarehouseStatus, EnumOrderWarehouseStatusOption } from "./EnumOrderWarehouseStatus";
import UiGroup from "./UiGroup";

/**
 * 进销存/出入库表单
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormOrderWarehouse extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.OrderWarehouseAnnexs = [];
        this.Packages = [];
        this.Status = EnumOrderWarehouseStatus.None;
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 编号
     */
    Code?: string;
    /**
     * 签订时间
     */
    SigningTime?: Date;
    /**
     * 合作方（采购方/供应商）
     */
    Partner?: string;
    /**
     * 合作方
     */
    PartnerId?: string;
    /**
     * 交货地
     */
    Address?: string;
    /**
     * 交期要求
     */
    Requirement?: string;
    /**
     * 电子版合同
     */
    OrderWarehouseAnnexs?: DtoFormOrderWarehouse_OrderWarehouseAnnex[];
    /**
     * 金额
     */
    Price?: number;
    /**
     * 币制
     */
    CurrencySystemId?: string;
    /**
     * 包装
     */
    Packages?: DtoFormOrderWarehouse_OrderWarehousePackage[];
    /**
     * 
     */
    Type?: EnumWarehouseType;
    /**
     * 状态
     */
    Status?: EnumOrderWarehouseStatus;
    /**
     * 选择的执行人主键
     */
    SelectJoinCompanyUserId?: string;
    /**
     * 执行人
     */
    JoinCompanyUserId?: string;
}

/**
 * 进销存/出入库表单-表单校验
 */
export const DtoFormOrderWarehouseRules = {
    Code: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
        {
            max: 64,
            message: "文本长度不能超过 64 个字符",
            trigger: "blur",
        },
    ],
}

/**
 * 进销存/出入库表单-设置项
 */
export const DtoFormOrderWarehouseMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "Code",
    Label: "编号",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "SigningTime",
    Label: "签订时间",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "Partner",
    Label: "合作方（采购方/供应商）",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "Address",
    Label: "交货地",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "Requirement",
    Label: "交期要求",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "OrderWarehouseAnnexs",
    Label: "电子版合同",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "CurrencySystemId",
    Label: "币制",
    Span: 24,
    Customize: true,
  },
  {
    Prop: "Packages",
    Label: "包装",
    Span: 12,
    Customize: true,
  },
  {
    Prop: "SelectJoinCompanyUserId",
    Label: "选择的执行人主键",
    Span: 12,
    Customize: true,
  },
]
