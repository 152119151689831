import UiSelectOption from "./UiSelectOption";

/**
 * 第三方接口计数类型
 * @description 自动生成的代码,请勿修改
 */
export enum EnumThreeApiCountType {
    /**
     * 箱号
     */
    ContainerNo = 10,
    /**
     * 提运单号
     */
    CustomsBlNo = 20,
    /**
     * 快递号
     */
    ExpressCode = 30,
}

/**
 * 第三方接口计数类型-选项
 */
export const EnumThreeApiCountTypeOption: UiSelectOption<number>[] = [
    { Label: "箱号", Value: 10, Data: "ContainerNo" },
    { Label: "提运单号", Value: 20, Data: "CustomsBlNo" },
    { Label: "快递号", Value: 30, Data: "ExpressCode" },
];
