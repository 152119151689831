
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import OrderPoolExpressAppService from "@/apis.machine/OrderPoolExpressAppService";
import DtoDetailOrderPoolExpressProcess from "@/models.machine/DtoDetailOrderPoolExpressProcess";
import DtoDetailOrderPoolExpressProcess_GetTrackInfo_Accepted from "@/models.machine/DtoDetailOrderPoolExpressProcess_GetTrackInfo_Accepted";
import "./base-vendor.min.css";
import { carrier } from "./carrier";
import DtoDetailOrderPoolExpressProcess_GetTrackInfo_Accepted_TrackInfo_Tracking_Providers from "@/models.machine/DtoDetailOrderPoolExpressProcess_GetTrackInfo_Accepted_TrackInfo_Tracking_Providers";
import MiezzModal, { MiezzModalType } from "@/models/MiezzModal";
import MiezzButton from "@/models/MiezzButton";
import DtoDetailOrderPoolExpressProcess_Rejected from "@/models.machine/DtoDetailOrderPoolExpressProcess_Rejected";
import MiezzForm, { EnumMiezzFormItemType } from "@/models/MiezzForm";
import DtoFormOrderPoolExpressCodeParam from "@/models.machine/DtoFormOrderPoolExpressCodeParam";
import DtoDetailOrderPoolExpressProcess_Code from "@/models.machine/DtoDetailOrderPoolExpressProcess_Code";
export default class ExpressTracking extends Vue {
  @Model() modelValue?: string;

  detail?: DtoDetailOrderPoolExpressProcess = {};
  code?: string = "";
  codeInfo?: DtoDetailOrderPoolExpressProcess_Code = {};
  tracking?: DtoDetailOrderPoolExpressProcess_GetTrackInfo_Accepted = {};
  provider?: DtoDetailOrderPoolExpressProcess_GetTrackInfo_Accepted_TrackInfo_Tracking_Providers =
    {};
  carrier?: any;
  rejected?: DtoDetailOrderPoolExpressProcess_Rejected;
  statusList = [
    { code: "NotFound", label: "查询不到", value: "00" },
    { code: "InfoReceived", label: "收到信息", value: "05" },
    { code: "InTransit", label: "运输途中", value: "10" },
    { code: "Expired", label: "运输过久", value: "20" },
    { code: "AvailableForPickup", label: "到达待取", value: "30" },
    { code: "OutForDelivery", label: "派送途中", value: "32" },
    { code: "DeliveryFailure", label: "投递失败", value: "35" },
    { code: "Delivered", label: "成功签收", value: "40" },
    { code: "Exception", label: "可能异常", value: "50" },
  ];
  params = [
    { code: 100623, type: "邮编", demo: "2487" },
    { code: 100688, type: "邮编", demo: "28224" },
    { code: 2061, type: "邮编", demo: "123456" },
    { code: 100027, type: "邮编", demo: "92000" },
    { code: 100484, type: "邮编", demo: "12345" },
    { code: 100842, type: "国家二字码", demo: "US" },
    { code: 100466, type: "邮编", demo: "12345" },
    { code: 100881, type: "邮编", demo: "LD3 8NL" },
    {
      code: 100431,
      type: "邮编、城市、国家、发件人、收件人",
      demo: "21000、广州、中国、张三、李四",
    },
    { code: 100189, type: "邮编", demo: "123456" },
    { code: 100384, type: "邮编", demo: "12345" },
    { code: 100646, type: "邮编", demo: "20740" },
    { code: 100594, type: "邮编", demo: "12345" },
    { code: 100388, type: "手机尾号（四位）", demo: "6172" },
    { code: 100271, type: "手机尾号（四位）", demo: "9996" },
    { code: 100456, type: "手机尾号（四位）", demo: "153" },
    { code: 100304, type: "邮编", demo: "123456" },
    { code: 100436, type: "Client", demo: "12345" },
    { code: 100436, type: "Origin office", demo: "1234" },
    { code: 100364, type: "邮编", demo: "12345" },
    { code: 100394, type: "邮编", demo: "CO14 8LF" },
    { code: 100900, type: "邮编", demo: "DD5 3BG" },
    { code: 14041, type: "国家二字码+邮编", demo: "FR-12345" },
    { code: 100522, type: "邮编", demo: "88888" },
    { code: 100167, type: "邮编", demo: "3078CM" },
    { code: 100524, type: "邮编", demo: "S27 1BZ" },
    { code: 100580, type: "邮编", demo: "TR197BG" },
    { code: 190766, type: "手机尾号（四位）", demo: "5678" },
  ];

  btnMore: MiezzButton = {
    Code: "more",
    Label: "更多信息",
    Type: "text",
    Size: "small",
  };
  modalParam = new MiezzModal(MiezzModalType.Dialog);
  formParam = new MiezzForm<DtoFormOrderPoolExpressCodeParam, string>();
  modalMore = new MiezzModal(MiezzModalType.Dialog);

  created(): void {
    this.onModelValueChanged(this.modelValue, undefined);

    this.modalMore.Title = "更多信息";
    this.modalMore.Width = "600px";
    this.modalMore.Height = "250px";
  }

  @Watch("modelValue")
  onModelValueChanged(nv: any, ov: any): void {
    if (nv != ov && this.modelValue) {
      OrderPoolExpressAppService.GetProcessById(this.modelValue).then((r) => {
        this.detail = r.data.Data;
        this.codeInfo = this.code
          ? this.detail?.Codes?.firstOrDefault((it) => it.Code == this.code)
          : this.detail?.Codes?.firstOrDefault();
        this.getTracking(this.codeInfo?.Code);
        if (this.detail?.UpdateEntity) {
          this.handleUpdateEntity();
        }
      });
    }
  }

  @Emit()
  handleUpdateEntity(): void {
    //
  }

  getTracking(code?: string): void {
    this.code = code;
    this.codeInfo = this.detail?.Codes?.firstOrDefault(
      (it) => it.Code == this.code
    );
    this.tracking = this.detail?.GetTrackInfo?.data?.accepted?.firstOrDefault(
      (it) => it.number == code
    );
    this.provider =
      this.tracking?.track_info?.tracking?.providers?.firstOrDefault();
    this.rejected = this.detail?.Register?.data?.rejected?.firstOrDefault(
      (it: any) => it.number == code
    );
    if (!this.rejected) {
      this.rejected = this.detail?.GetTrackInfo?.data?.rejected?.firstOrDefault(
        (it) => it.number == code
      );
      if (this.rejected?.error?.code == -18019909) {
        setTimeout(() => {
          this.onModelValueChanged(this.modelValue, undefined);
        }, 3000);
      }
    }
    this.carrier = carrier.firstOrDefault(
      (it) => it.key == this.provider?.provider?.key
    );
  }

  handleClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      case "save-param":
        MiezzForm.submit(this.$refs, "ref-form-param", (v) => {
          if (v) {
            OrderPoolExpressAppService.PutCodeParam(this.formParam.Data)
              .then(() => {
                this.onModelValueChanged(this.modelValue, undefined);
                this.modalParam.Show = false;
                complete();
              })
              .catch(complete);
          } else {
            complete();
          }
        });
        complete();
        break;
      case "more":
        this.modalMore.Show = true;
        complete();
        break;
      default:
        break;
    }
    complete();
  }

  handleInfo(): void {
    this.modalParam.Title = "补充信息";
    this.modalParam.Width = "500px";
    this.modalParam.Height = "200px";
    this.modalParam.HandleClick = this.handleClick;
    this.modalParam.Buttons = [
      {
        Code: "save-param",
        Label: "保存",
        Type: "primary",
        Size: "small",
      },
    ];
    this.formParam.LabelPosition = "top";
    this.formParam.Data = this.codeInfo ?? {};
    this.formParam.Items = [];
    this.formParam.Rules = {};
    if (
      this.rejected?.error?.code == -18019903 ||
      this.formParam.Data.CarrierId
    ) {
      this.formParam.Items.push({
        Prop: "CarrierId",
        Label: "运输商",
        Type: EnumMiezzFormItemType.Select,
        Options: carrier.map((it: any) => {
          return {
            Label: it["_name_zh-cn"],
            Value: it.key,
          };
        }),
      });
      this.formParam.Rules.CarrierId = [
        {
          required: true,
          message: "信息不能为空",
          trigger: "change",
        },
      ];
    }
    this.onCarrierIdChanged();
    this.modalParam.Show = true;
  }

  @Watch("formParam.Data.CarrierId")
  onCarrierIdChanged(): void {
    const param = this.params.firstOrDefault(
      (it) => it.code == this.formParam.Data.CarrierId
    );
    if (
      this.rejected?.error?.code == -18010020 ||
      param?.type == "手机尾号（四位）"
    ) {
      const itemParam = this.formParam.Items?.firstOrDefault(
        (it) => it.Prop == "Param"
      );
      if (itemParam) {
        itemParam.Label = "手机尾号后四位";
        itemParam.Description = "示例：'8888'。";
      } else {
        this.formParam.Items?.push({
          Prop: "Param",
          Label: "手机尾号后四位",
          Type: EnumMiezzFormItemType.InputText,
          Description: "示例：'8888'。",
        });
      }
      this.formParam.Rules.Param = [
        {
          required: true,
          message: "信息不能为空",
          trigger: "blur",
        },
        {
          validator: (rule: any, value: string, callback: any) => {
            if (value) {
              if (!/^[0-9]{4}$/.test(value)) {
                return callback(new Error("手机尾号格式错误"));
              }
            }
            callback();
          },
          trigger: "blur",
        },
      ];
    } else if (param?.type) {
      const itemParam = this.formParam.Items?.firstOrDefault(
        (it) => it.Prop == "Param"
      );
      if (itemParam) {
        itemParam.Label = param.type;
        itemParam.Description = `示例：'${param.demo}'。`;
      } else {
        this.formParam.Items?.push({
          Prop: "Param",
          Label: param.type,
          Type: EnumMiezzFormItemType.InputText,
          Description: `示例：'${param.demo}'。`,
        });
      }
      this.formParam.Rules.Param = [
        {
          required: true,
          message: "信息不能为空",
          trigger: "blur",
        },
      ];
    } else if (this.rejected?.error?.code == -18010019) {
      const itemParam = this.formParam.Items?.firstOrDefault(
        (it) => it.Prop == "Param"
      );
      if (itemParam) {
        itemParam.Label = "邮编";
        itemParam.Description = "示例：'3078CM'。";
      } else {
        this.formParam.Items?.push({
          Prop: "Param",
          Label: "邮编",
          Type: EnumMiezzFormItemType.InputText,
          Description: "示例：'3078CM'。",
        });
      }
      this.formParam.Rules.Param = [
        {
          required: true,
          message: "信息不能为空",
          trigger: "blur",
        },
      ];
    } else if (this.rejected?.error?.code == -18010018) {
      const itemParam = this.formParam.Items?.firstOrDefault(
        (it) => it.Prop == "Param"
      );
      if (itemParam) {
        itemParam.Label = "国家二字码和邮编";
        itemParam.Description = "示例：'FR-12345'。";
      } else {
        this.formParam.Items?.push({
          Prop: "Param",
          Label: "国家二字码和邮编",
          Type: EnumMiezzFormItemType.InputText,
          Description: "示例：'FR-12345'。",
        });
      }
      this.formParam.Rules.Param = [
        {
          required: true,
          message: "信息不能为空",
          trigger: "blur",
        },
      ];
    } else {
      this.formParam.Items = this.formParam.Items?.filter(
        (it) => it.Prop != "Param"
      );
      this.formParam.Data.Param = undefined;
    }
  }
}
