import { AxiosPromise } from "axios";
import service from "../apis/Service";
import RESTfulResult from "../models.machine/RESTfulResult";
import UiSelectOption from "../models.machine/UiSelectOption";
import DtoPageListSearchThreeApiCount from "../models.machine/DtoPageListSearchThreeApiCount";
import DtoPageListThreeApiCount from "../models.machine/DtoPageListThreeApiCount";

/**
 * 第三方接口计数
 * @description 自动生成的代码,请勿修改
 */
export default class ThreeApiCountAppService {
    /**
     * 获取服务商名称
     * @returns {AxiosPromise<RESTfulResult<UiSelectOption<string>[]>>} 
     */
    static GetServiceProviderCompanies(): AxiosPromise<RESTfulResult<UiSelectOption<string>[]>> {
        const url = `/api/ThreeApiCount/GetServiceProviderCompanies`;
        return service.get<RESTfulResult<UiSelectOption<string>[]>>(url);
    }

    /**
     * 获取第三方接口计数分页列表
     * @param {DtoPageListSearchThreeApiCount} [dto] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListThreeApiCount>>} 分页列表
     */
    static Get(dto?: DtoPageListSearchThreeApiCount): AxiosPromise<RESTfulResult<DtoPageListThreeApiCount>> {
        const url = `/api/ThreeApiCount/Get`;
        return service.get<RESTfulResult<DtoPageListThreeApiCount>>(url, { params: dto });
    }

}
