import UiSelectOption from "./UiSelectOption";

/**
 * 航程单位
 * @description 自动生成的代码,请勿修改
 */
export enum EnumVoyageUnit {
    /**
     * Hour
     */
    Hour = 1,
    /**
     * Day
     */
    Day = 24,
}

/**
 * 航程单位-选项
 */
export const EnumVoyageUnitOption: UiSelectOption<number>[] = [
    { Label: "Hour", Value: 1, Data: "Hour" },
    { Label: "Day", Value: 24, Data: "Day" },
];
