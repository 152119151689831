import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import UiGroup from "./UiGroup";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoPutOrderPoolExpress_CarryAddress_CompanyAddress extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 公司名称
     */
    CompanyName?: string;
    /**
     * 坐标
     */
    AreaLabel?: string;
    /**
     * 详细地址
     */
    Address?: string;
    /**
     * 联系人
     */
    Contact?: string;
    /**
     * 联系方式
     */
    ContactNumber?: string;
    /**
     * 备注
     */
    Remark?: string;
}

/**
 * -表单校验
 */
export const DtoPutOrderPoolExpress_CarryAddress_CompanyAddressRules = {
    CompanyName: [
        {
            max: 1024,
            message: "文本长度不能超过 1024 个字符",
            trigger: "blur",
        },
    ],
    AreaLabel: [
        {
            max: 10240,
            message: "文本长度不能超过 10240 个字符",
            trigger: "blur",
        },
    ],
    Address: [
        {
            max: 10240,
            message: "文本长度不能超过 10240 个字符",
            trigger: "blur",
        },
    ],
    Contact: [
        {
            max: 256,
            message: "文本长度不能超过 256 个字符",
            trigger: "blur",
        },
    ],
    ContactNumber: [
        {
            max: 1024,
            message: "文本长度不能超过 1024 个字符",
            trigger: "blur",
        },
    ],
    Remark: [
        {
            max: 10240,
            message: "文本长度不能超过 10240 个字符",
            trigger: "blur",
        },
    ],
}

/**
 * -设置项
 */
export const DtoPutOrderPoolExpress_CarryAddress_CompanyAddressMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "CompanyName",
    Label: "公司名称",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "AreaLabel",
    Label: "坐标",
    Type: EnumMiezzFormItemType.AreaText,
  },
  {
    Prop: "Address",
    Label: "详细地址",
    Type: EnumMiezzFormItemType.AreaText,
  },
  {
    Prop: "Contact",
    Label: "联系人",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "ContactNumber",
    Label: "联系方式",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Remark",
    Label: "备注",
    Type: EnumMiezzFormItemType.AreaText,
  },
]
