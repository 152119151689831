
    import { Vue, Options } from "vue-class-component";
    import { Emit, Model, Prop, Watch } from "vue-property-decorator";
    import { ElMessage } from "element-plus";
    import OrderStowageStackPageList from "./OrderStowageStackPageList.vue";
    import MiezzPageList from "@/models/MiezzPageList";
    import DtoPageListSearchOrderStowageStack from "@/models.machine/DtoPageListSearchOrderStowageStack";
    import DtoListItemOrderStowageStack from "@/models.machine/DtoListItemOrderStowageStack";
    import { EnumTransportDirection } from "@/models.machine/EnumTransportDirection";
    import { EnumTransportType } from "@/models.machine/EnumTransportType";
    import { EnumTransportContainerLoad } from "@/models.machine/EnumTransportContainerLoad";

    @Options({
        components: {
            OrderStowageStackPageList,
        },
    })
    export default class OrderStowageStackEAPageList extends Vue {
        /**分页列表 */
        list = new MiezzPageList<
            DtoPageListSearchOrderStowageStack,
            DtoListItemOrderStowageStack,
            string
        >("OrderStowageStack_EA");
        direction = EnumTransportDirection.Export;
        type = EnumTransportType.Air;
        containerLoad?: EnumTransportContainerLoad = undefined;

        /**创建时 */
        created(): void {
            //列表-底部按钮
            this.list["_BottomButtons"] = [
                {
                    Code: "create",
                    Label: "创建订单",
                    Icon: "edit",
                    Type: "text",
                    Size: "small",
                    PowerCode: "OrderStowageStack_Create",
                },
                {
                    Code: "allocate",
                    Label: "调配",
                    MiezzIcon: "assign",
                    Type: "text",
                    Size: "small",
                    PowerCode: "OrderStowageStack_Allocate",
                },
                {
                    Code: "cancel",
                    Label: "取消配载",
                    Icon: "RefreshLeft",
                    Type: "text",
                    Message: "是否确认取消配载",
                    Size: "small",
                    PowerCode: "OrderStowageStack_Cancel",
                },
                {
                    Code: "delete",
                    Label: "删除",
                    Icon: "delete",
                    Type: "text",
                    Message: "删除后信息无法恢复，通过《拼单配载》生成的订单将被保留，如需删除请至《订单列表》中进行删除操作，是否继续？",
                    Size: "small",
                    PowerCode: "OrderStowageStack_Delete",
                },

                {
                    Code: "prePackedBox",
                    Label: "添加到拼单预览",
                    Icon: "ShoppingCart",
                    Type: "text",
                    Size: "small",
                    PowerCode: "OrderStowageStack_PrePackedBox"
                },
            ];
        }
    }
