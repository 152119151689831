
import { Vue, Options } from "vue-class-component";
import { Emit, Model, Prop, Watch } from "vue-property-decorator";
import { ElMessage } from "element-plus";
import MiezzPageList from "@/models/MiezzPageList";
import MiezzModal from "@/models/MiezzModal";
import MiezzButton from "@/models/MiezzButton";
import ThreeApiCountAppService from "@/apis.machine/ThreeApiCountAppService";
import DtoPageListSearchThreeApiCount, {
  DtoPageListSearchThreeApiCountDynamicSearchItems,
} from "@/models.machine/DtoPageListSearchThreeApiCount";
import DtoPageListThreeApiCount from "@/models.machine/DtoPageListThreeApiCount";
import DtoListItemThreeApiCount, {
  DtoListItemThreeApiCountMiezzListItems,
} from "@/models.machine/DtoListItemThreeApiCount";
import { EnumThreeApiCountTypeOption } from "@/models.machine/EnumThreeApiCountType";
import { EnumThreeApiCountSupplierOption } from "@/models.machine/EnumThreeApiCountSupplier";
import { number } from "echarts";
import UiSelectOption from "@/models.machine/UiSelectOption";

@Options({
  components: {},
})
export default class ThreeApiCountPageList extends Vue {
  /**分页列表 */
  list = new MiezzPageList<
    DtoPageListSearchThreeApiCount,
    DtoListItemThreeApiCount,
    string
  >();
  /**当前Id */
  id?: string;

  EnumThreeApiCountTypeOption = EnumThreeApiCountTypeOption;
  EnumThreeApiCountSupplierOption = EnumThreeApiCountSupplierOption;
  serviceProviderCompanies: UiSelectOption<string>[] = [];

  /**创建时 */
  created(): void {
    //列表-操作区域宽度
    //this.list.OperationWidth = "100px";
    //列表-传参
    this.list.Dto = new DtoPageListSearchThreeApiCount();
    //列表-动态搜索-项
    this.list.DynamicSearchItems =
      DtoPageListSearchThreeApiCountDynamicSearchItems;
    //列表-数据-初始
    this.list.Data = new DtoPageListThreeApiCount();
    //列表-列配置
    this.list.Items = DtoListItemThreeApiCountMiezzListItems;
    //列表-行按钮
    this.list.RowButtons = [];
    //列表-底部按钮
    this.list.BottomButtons = [];

    ThreeApiCountAppService.GetServiceProviderCompanies().then((r) => {
      this.serviceProviderCompanies = r.data.Data ?? [];
    });
  }

  /**查询列表 */
  handleGet(): void {
    ThreeApiCountAppService.Get(this.list.Dto).then((r) => {
      this.list.Data = r.data.Data;
    });
  }

  /**顶部按钮点击 */
  handleTopButtonClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      default:
        break;
    }
  }

  /**行按钮点击 */
  handleRowButtonClick(
    btn: MiezzButton,
    row: DtoListItemThreeApiCount,
    complete: () => void
  ): void {
    this.id = row.Id;
    switch (btn.Code) {
      default:
        break;
    }
  }

  /**底部按钮点击 */
  handleBottomButtonClick(btn: MiezzButton, complete: () => void): void {
    switch (btn.Code) {
      default:
        break;
    }
  }
}
