import UiSelectOption from "./UiSelectOption";

/**
 * 第三方接口计数供应商
 * @description 自动生成的代码,请勿修改
 */
export enum EnumThreeApiCountSupplier {
    /**
     * 飞鸵
     */
    FreighTower = 10,
    /**
     * 17TRACK
     */
    Track17 = 20,
}

/**
 * 第三方接口计数供应商-选项
 */
export const EnumThreeApiCountSupplierOption: UiSelectOption<number>[] = [
    { Label: "飞鸵", Value: 10, Data: "FreighTower" },
    { Label: "17TRACK", Value: 20, Data: "Track17" },
];
