import UiForm from "./UiForm";
import { EnumMiezzFormItemType, MiezzFormItem } from "@/models/MiezzForm";
import { EnumProductSiteEnvironment, EnumProductSiteEnvironmentOption } from "./EnumProductSiteEnvironment";
import UiGroup from "./UiGroup";

/**
 * 项目-站点表单
 * @description 自动生成的代码,请勿修改
 */
export default class DtoFormProjectSite extends UiForm<string> {
    constructor() {
        //无参构造函数
        super()
        this.Official = false;
        this.UiGroups = [];
    }

    [proName: string]: any;
    /**
     * 项目Id
     */
    ProjectId?: string;
    /**
     * 名称
     */
    Name?: string;
    /**
     * 编码
     */
    Code?: string;
    /**
     * 域名
     */
    Domain?: string;
    /**
     * 站点-Ip4
     */
    SiteIp4?: string;
    /**
     * 站点-IIS应用名称
     */
    SiteIisName?: string;
    /**
     * 站点-地址
     */
    SitePath?: string;
    /**
     * 数据库-连接地址
     */
    DbConnectionString?: string;
    /**
     * 环境
     */
    Environment?: EnumProductSiteEnvironment;
    /**
     * 是否为官方
     */
    Official?: boolean;
}

/**
 * 项目-站点表单-表单校验
 */
export const DtoFormProjectSiteRules = {
    ProjectId: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "change",
        },
    ],
    Name: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
        {
            max: 128,
            message: "文本长度不能超过 128 个字符",
            trigger: "blur",
        },
    ],
    Code: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
        {
            max: 128,
            message: "文本长度不能超过 128 个字符",
            trigger: "blur",
        },
    ],
    Domain: [
        {
            max: 128,
            message: "文本长度不能超过 128 个字符",
            trigger: "blur",
        },
    ],
    SiteIp4: [
        {
            max: 15,
            message: "文本长度不能超过 15 个字符",
            trigger: "blur",
        },
    ],
    SiteIisName: [
        {
            max: 128,
            message: "文本长度不能超过 128 个字符",
            trigger: "blur",
        },
    ],
    SitePath: [
        {
            max: 1024,
            message: "文本长度不能超过 1024 个字符",
            trigger: "blur",
        },
    ],
    DbConnectionString: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "blur",
        },
        {
            max: 1024,
            message: "文本长度不能超过 1024 个字符",
            trigger: "blur",
        },
    ],
    Environment: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "change",
        },
    ],
    Official: [
        {
            required: true,
            message: "信息不能为空",
            trigger: "change",
        },
    ],
}

/**
 * 项目-站点表单-设置项
 */
export const DtoFormProjectSiteMiezzFormItems: MiezzFormItem[] = [
  {
    Prop: "ProjectId",
    Label: "项目Id",
    Type: EnumMiezzFormItemType.Select,
  },
  {
    Prop: "Name",
    Label: "名称",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Code",
    Label: "编码",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Domain",
    Label: "域名",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "SiteIp4",
    Label: "站点-Ip4",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "SiteIisName",
    Label: "站点-IIS应用名称",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "SitePath",
    Label: "站点-地址",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "DbConnectionString",
    Label: "数据库-连接地址",
    Type: EnumMiezzFormItemType.InputText,
  },
  {
    Prop: "Environment",
    Label: "环境",
    Type: EnumMiezzFormItemType.Select,
    Options: EnumProductSiteEnvironmentOption,
  },
  {
    Prop: "Official",
    Label: "是否为官方",
    Type: EnumMiezzFormItemType.Switch,
  },
]
