import UiListItem from "./UiListItem";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import DtoListItemOrderPoolContainerTrailer_OrderPool from "./DtoListItemOrderPoolContainerTrailer_OrderPool";
import DtoListItemOrderPoolContainerTrailer_ContainerType from "./DtoListItemOrderPoolContainerTrailer_ContainerType";
import DtoListItemOrderPoolContainerTrailer_OrderPoolContainerTrailerAddress from "./DtoListItemOrderPoolContainerTrailer_OrderPoolContainerTrailerAddress";
import { EnumSupplierSource, EnumSupplierSourceOption } from "./EnumSupplierSource";
import DtoListItemOrderPoolContainerTrailer_OrderPoolFile from "./DtoListItemOrderPoolContainerTrailer_OrderPoolFile";

/**
 * 委托书-集装箱拖车分页列表-项
 * @description 自动生成的代码,请勿修改
 */
export default class DtoListItemOrderPoolContainerTrailer extends UiListItem<string> {
    constructor() {
        //无参构造函数
        super()
        this.Addresses = [];
        this.ExecutorLabel = "暂无数据";
        this.Files = [];
        this.QueryThreeApiPropList = [];
        this.BindThreeApiPropList = [];
    }

    [proName: string]: any;
    /**
     * 编号
     */
    Code?: string;
    /**
     * 委托书Id
     */
    PoolId?: string;
    /**
     * 委托书
     */
    Pool?: DtoListItemOrderPoolContainerTrailer_OrderPool;
    /**
     * 委托人
     */
    ClientCompany?: string;
    /**
     * 集装箱箱型Id
     */
    ContainerTypeId?: string;
    /**
     * 集装箱箱型
     */
    ContainerType?: DtoListItemOrderPoolContainerTrailer_ContainerType;
    /**
     * 箱型
     */
    ContainerTypeLabel?: string;
    /**
     * 计划时间
     */
    CarryPlanTime?: Date;
    /**
     * 提货地址与时间
     */
    Addresses?: DtoListItemOrderPoolContainerTrailer_OrderPoolContainerTrailerAddress[];
    /**
     * 抵达时间
     */
    ArrivalTime?: Date;
    /**
     * 供应商来源
     */
    SupplierSource?: EnumSupplierSource;
    /**
     * 供应商类型Id
     */
    SupplierCompanyTypeId?: string;
    /**
     * 供应商类型
     */
    SupplierCompanyTypeLabel?: string;
    /**
     * 供应商Id
     */
    SupplierCompanyId?: string;
    /**
     * 供应商
     */
    SupplierCompanyLabel?: string;
    /**
     * 供应商联系人
     */
    SupplierCompanyContactLabel?: string;
    /**
     * 供应商
     */
    SupplierLabel?: string;
    /**
     * 执行人姓名
     */
    Executor?: string;
    /**
     * 执行人联系方式
     */
    ExecutorContactNumber?: string;
    /**
     * 执行人车牌号码
     */
    ExecutorLicensePlate?: string;
    /**
     * 执行人
     */
    ExecutorLabel?: string;
    /**
     * 提柜地点
     */
    CarryContainerAreaLabel?: string;
    /**
     * 计划提柜时间
     */
    PlanCarryContainerTime?: Date;
    /**
     * 实际提柜时间
     */
    CarryContainerTime?: Date;
    /**
     * 进港码头
     */
    IntoPortWharfLabel?: string;
    /**
     * 还柜时间
     */
    BackContainerTime?: Date;
    /**
     * 箱号
     */
    ContainerNo?: string;
    /**
     * 皮重
     */
    Tare?: number;
    /**
     * 箱号照片
     */
    ContainerNoFile?: string;
    /**
     * 封号
     */
    SealNo?: string;
    /**
     * 封号照片
     */
    SealNoFile?: string;
    /**
     * 附件
     */
    Files?: DtoListItemOrderPoolContainerTrailer_OrderPoolFile[];
    /**
     * 进程
     */
    Process?: string;
    /**
     * 第三方接口-回调数据-可查询的字段
     */
    QueryThreeApiPropList?: string[];
    /**
     * 第三方接口-回调数据-可查询的字段
     */
    QueryThreeApiProps?: string;
    /**
     * 第三方接口-回调数据-已绑定的字段
     */
    BindThreeApiPropList?: string[];
    /**
     * 第三方接口-回调数据-已绑定的字段
     */
    BindThreeApiProps?: string;
}

/**
 * 委托书-集装箱拖车分页列表-项-表单校验
 */
export const DtoListItemOrderPoolContainerTrailerRules = {
}

/**
 * 委托书-集装箱拖车分页列表-项-设置项
 */
export const DtoListItemOrderPoolContainerTrailerMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "Code",
    Label: "编号",
    MaxWidth: "100px",
  },
  {
    Prop: "ClientCompany",
    Label: "委托人",
    MaxWidth: "150px",
  },
  {
    Prop: "ContainerTypeLabel",
    Label: "箱型",
    Customize: true,
  },
  {
    Prop: "CarryPlanTime",
    Label: "计划时间",
    MaxWidth: "150px",
    Customize: true,
  },
  {
    Prop: "Addresses",
    Label: "提货地址与时间",
    MaxWidth: "130px",
    Customize: true,
  },
  {
    Prop: "ArrivalTime",
    Label: "抵达时间",
    MaxWidth: "150px",
    Customize: true,
  },
  {
    Prop: "SupplierLabel",
    Label: "供应商",
    Customize: true,
  },
  {
    Prop: "ExecutorLabel",
    Label: "执行人",
    Customize: true,
  },
  {
    Prop: "CarryContainerAreaLabel",
    Label: "提柜地点",
    MaxWidth: "100px",
    Customize: true,
  },
  {
    Prop: "PlanCarryContainerTime",
    Label: "计划提柜时间",
    MaxWidth: "150px",
    Customize: true,
  },
  {
    Prop: "CarryContainerTime",
    Label: "实际提柜时间",
    MaxWidth: "150px",
    Customize: true,
  },
  {
    Prop: "IntoPortWharfLabel",
    Label: "进港码头",
    MaxWidth: "90px",
    Customize: true,
  },
  {
    Prop: "BackContainerTime",
    Label: "还柜时间",
    MaxWidth: "150px",
    Customize: true,
  },
  {
    Prop: "ContainerNo",
    Label: "箱号",
    Customize: true,
  },
  {
    Prop: "SealNo",
    Label: "封号",
    Customize: true,
  },
  {
    Prop: "Files",
    Label: "附件",
    Customize: true,
  },
  {
    Prop: "Process",
    Label: "进程",
    Customize: true,
  },
]
