import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_miezz_select = _resolveComponent("miezz-select")!
  const _component_miezz_page_list = _resolveComponent("miezz-page-list")!

  return (_openBlock(), _createBlock(_component_miezz_page_list, {
    modelValue: _ctx.list,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.list) = $event)),
    onHandleGet: _ctx.handleGet,
    onHandleTopButtonClick: _ctx.handleTopButtonClick,
    onHandleRowButtonClick: _ctx.handleRowButtonClick,
    onHandleBottomButtonClick: _ctx.handleBottomButtonClick
  }, {
    "dynamic-search-customize": _withCtx(({ model }) => [
      (model.Where.Prop == 'DynamicSearch_Type')
        ? (_openBlock(), _createBlock(_component_miezz_select, {
            key: 0,
            modelValue: model.Where.Value,
            "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
            options: _ctx.EnumThreeApiCountTypeOption,
            number: true
          }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
        : _createCommentVNode("", true),
      (model.Where.Prop == 'DynamicSearch_Supplier')
        ? (_openBlock(), _createBlock(_component_miezz_select, {
            key: 1,
            modelValue: model.Where.Value,
            "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
            options: _ctx.EnumThreeApiCountSupplierOption,
            number: true
          }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
        : _createCommentVNode("", true),
      (model.Where.Prop == 'DynamicSearch_ServiceProviderCompanyId')
        ? (_openBlock(), _createBlock(_component_miezz_select, {
            key: 2,
            modelValue: model.Where.Value,
            "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
            options: _ctx.serviceProviderCompanies
          }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["modelValue", "onHandleGet", "onHandleTopButtonClick", "onHandleRowButtonClick", "onHandleBottomButtonClick"]))
}