import { AxiosPromise } from "axios";
import service from "../apis/Service";
import DtoPageListSearchBillHistoryRecord from "../models.machine/DtoPageListSearchBillHistoryRecord";
import RESTfulResult from "../models.machine/RESTfulResult";
import DtoPageListBillHistoryRecord from "../models.machine/DtoPageListBillHistoryRecord";

/**
 * 账单历史记录
 * @description 自动生成的代码,请勿修改
 */
export default class BillHistoryRecordAppService {
    /**
     * 获取账单历史记录分页列表
     * @param {DtoPageListSearchBillHistoryRecord} [dto] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListBillHistoryRecord>>} 分页列表
     */
    static Get(dto?: DtoPageListSearchBillHistoryRecord): AxiosPromise<RESTfulResult<DtoPageListBillHistoryRecord>> {
        const url = `/api/BillHistoryRecord/Get`;
        return service.get<RESTfulResult<DtoPageListBillHistoryRecord>>(url, { params: dto });
    }

    /**
     * 获取账单历史记录分页列表
     * @param {DtoPageListSearchBillHistoryRecord} [dto] 搜索条件
     * @returns {AxiosPromise<RESTfulResult<DtoPageListBillHistoryRecord>>} 分页列表
     */
    static All(dto?: DtoPageListSearchBillHistoryRecord): AxiosPromise<RESTfulResult<DtoPageListBillHistoryRecord>> {
        const url = `/api/BillHistoryRecord/All`;
        return service.post<RESTfulResult<DtoPageListBillHistoryRecord>>(url, dto);
    }

}
