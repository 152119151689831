import UiListItem from "./UiListItem";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import { EnumThreeApiCountType, EnumThreeApiCountTypeOption } from "./EnumThreeApiCountType";
import { EnumThreeApiCountSupplier, EnumThreeApiCountSupplierOption } from "./EnumThreeApiCountSupplier";

/**
 * 第三方接口计数分页列表-项
 * @description 自动生成的代码,请勿修改
 */
export default class DtoListItemThreeApiCount extends UiListItem<string> {
    constructor() {
        //无参构造函数
        super()
    }

    [proName: string]: any;
    /**
     * 类型
     */
    Type?: EnumThreeApiCountType;
    /**
     * 编号
     */
    Code?: string;
    /**
     * MBL NO.
     */
    MblNo?: string;
    /**
     * 创建公司所属服务商Id
     */
    CreatedServiceProviderCompanyId?: string;
    /**
     * 服务商名称
     */
    CreatedServiceProviderCompanyLabel?: string;
    /**
     * 接口供应商名称
     */
    Supplier?: EnumThreeApiCountSupplier;
    /**
     * 调用时间
     */
    CreatedTime?: Date;
}

/**
 * 第三方接口计数分页列表-项-表单校验
 */
export const DtoListItemThreeApiCountRules = {
}

/**
 * 第三方接口计数分页列表-项-设置项
 */
export const DtoListItemThreeApiCountMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "Type",
    Label: "类型",
    Pipe: EnumPipe.Enum,
    Options: EnumThreeApiCountTypeOption,
  },
  {
    Prop: "Code",
    Label: "编号",
  },
  {
    Prop: "MblNo",
    Label: "MBL NO.",
  },
  {
    Prop: "CreatedServiceProviderCompanyLabel",
    Label: "服务商名称",
  },
  {
    Prop: "Supplier",
    Label: "接口供应商名称",
    Pipe: EnumPipe.Enum,
    Options: EnumThreeApiCountSupplierOption,
  },
  {
    Prop: "CreatedTime",
    Label: "调用时间",
    MaxWidth: "150px",
    Pipe: EnumPipe.Time,
  },
]
