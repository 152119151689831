import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fc45019c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"overflow":"hidden","white-space":"nowrap","text-overflow":"ellipsis","cursor":"pointer","width":"170px"} }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_miezz_select = _resolveComponent("miezz-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_miezz_input = _resolveComponent("miezz-input")!
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_miezz_form = _resolveComponent("miezz-form")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_miezz_button = _resolveComponent("miezz-button")!
  const _component_el_alert = _resolveComponent("el-alert")!
  const _component_vuedraggable = _resolveComponent("vuedraggable")!
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!
  const _component_miezz_modal = _resolveComponent("miezz-modal")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives((_openBlock(), _createElementBlock("div", {
      class: "form-wrapper",
      style: _normalizeStyle(_ctx.id ? 'width: 100%' : '')
    }, [
      (_ctx.form.Data)
        ? (_openBlock(), _createBlock(_component_miezz_form, {
            key: 0,
            ref: "ruleForm",
            modelValue: _ctx.form,
            "onUpdate:modelValue": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.form) = $event))
          }, {
            "form-item": _withCtx(({ item }) => [
              (item.Prop == 'ClientCompanyId')
                ? (_openBlock(), _createBlock(_component_el_form_item, {
                    key: 0,
                    prop: item.Prop,
                    label: item.Label
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_miezz_select, {
                        ref: "ref-client-company-id",
                        modelValue: _ctx.form.Data.ClientCompanyId,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.Data.ClientCompanyId) = $event)),
                        label: _ctx.form.Data.ClientCompanyLabel,
                        "onUpdate:label": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.Data.ClientCompanyLabel) = $event)),
                        options: _ctx.filterCompanies,
                        "filter-Method": _ctx.filterClientCompanies,
                        disabled: item.Disabled && !_ctx.stock,
                        labelKey: "NameZh/NameEn",
                        valueKey: "Id"
                      }, null, 8, ["modelValue", "label", "options", "filter-Method", "disabled"])
                    ]),
                    _: 2
                  }, 1032, ["prop", "label"]))
                : (item.Prop == 'ClientUserId')
                  ? (_openBlock(), _createBlock(_component_el_form_item, {
                      key: 1,
                      prop: item.Prop,
                      label: item.Label
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_miezz_select, {
                          modelValue: _ctx.form.Data.ClientUserId,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.Data.ClientUserId) = $event)),
                          label: _ctx.form.Data.ClientUserLabel,
                          "onUpdate:label": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.Data.ClientUserLabel) = $event)),
                          options: _ctx.users,
                          disabled: item.Disabled && !_ctx.stock
                        }, null, 8, ["modelValue", "label", "options", "disabled"])
                      ]),
                      _: 2
                    }, 1032, ["prop", "label"]))
                  : (item.Prop == 'Code')
                    ? (_openBlock(), _createBlock(_component_el_form_item, {
                        key: 2,
                        label: 
            _ctx.logier?.CompanyType == _ctx.EnumCompanyType.ServiceProvider
              ? '委托方的编号'
              : '我的编号'
          
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_miezz_input, {
                            modelValue: _ctx.form.Data.Code,
                            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.Data.Code) = $event)),
                            trimAll: true,
                            disabled: _ctx.form.Data.Status == _ctx.EnumOrderPoolStatus.Completed,
                            onHandleBlur: _ctx.handleCodeBlur
                          }, null, 8, ["modelValue", "disabled", "onHandleBlur"])
                        ]),
                        _: 1
                      }, 8, ["label"]))
                    : (item.Prop == 'TransportDirection')
                      ? (_openBlock(), _createBlock(_component_el_form_item, {
                          key: 3,
                          label: item.Label,
                          required: ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_form_item, {
                              prop: "TransportDirection",
                              class: "direction"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_miezz_select, {
                                  modelValue: _ctx.form.Data.TransportDirection,
                                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.form.Data.TransportDirection) = $event)),
                                  options: _ctx.EnumTransportDirectionOption,
                                  disabled: 
                (_ctx.id != undefined ||
                  _ctx.logier?.CompanyType == _ctx.EnumCompanyType.Customer) &&
                !_ctx.stock
              
                                }, null, 8, ["modelValue", "options", "disabled"])
                              ]),
                              _: 1
                            }),
                            (_ctx.form.Data.TransportDirection != _ctx.EnumTransportDirection.Other)
                              ? (_openBlock(), _createBlock(_component_el_form_item, {
                                  key: 0,
                                  prop: "TransportType",
                                  class: "type"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_miezz_select, {
                                      modelValue: _ctx.form.Data.TransportType,
                                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.form.Data.TransportType) = $event)),
                                      options: _ctx.EnumTransportTypeOption,
                                      disabled: 
                (_ctx.id != undefined ||
                  _ctx.logier?.CompanyType == _ctx.EnumCompanyType.Customer) &&
                !_ctx.stock
              
                                    }, null, 8, ["modelValue", "options", "disabled"])
                                  ]),
                                  _: 1
                                }))
                              : _createCommentVNode("", true),
                            (
              _ctx.form.Data.TransportDirection != _ctx.EnumTransportDirection.Other &&
              (_ctx.form.Data.TransportType == _ctx.EnumTransportType.Sea ||
                _ctx.form.Data.TransportType == _ctx.EnumTransportType.Railway)
            )
                              ? (_openBlock(), _createBlock(_component_el_form_item, {
                                  key: 1,
                                  prop: "TransportContainerLoad",
                                  class: "containerLoad"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_miezz_select, {
                                      modelValue: _ctx.form.Data.TransportContainerLoad,
                                      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.form.Data.TransportContainerLoad) = $event)),
                                      options: _ctx.EnumTransportContainerLoadOption,
                                      disabled: 
                (_ctx.id != undefined ||
                  _ctx.logier?.CompanyType == _ctx.EnumCompanyType.Customer) &&
                !_ctx.stock
              
                                    }, null, 8, ["modelValue", "options", "disabled"])
                                  ]),
                                  _: 1
                                }))
                              : _createCommentVNode("", true)
                          ]),
                          _: 2
                        }, 1032, ["label"]))
                      : (
            item.Prop == 'DeparturePortBooking' &&
            _ctx.form.Data.TransportDirection != _ctx.EnumTransportDirection.Other
          )
                        ? (_openBlock(), _createBlock(_component_el_form_item, {
                            key: 4,
                            prop: item.Prop,
                            label: item.Label,
                            class: "services-check-group"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_checkbox, {
                                modelValue: _ctx.form.Data.DeparturePortBooking,
                                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.form.Data.DeparturePortBooking) = $event)),
                                label: "订舱",
                                disabled: 
              _ctx.logier?.CompanyType == _ctx.EnumCompanyType.Customer ||
              _ctx.form.Data.Status == _ctx.EnumOrderPoolStatus.Completed
            
                              }, null, 8, ["modelValue", "disabled"]),
                              (_ctx.form.Data.DeparturePortBooking)
                                ? (_openBlock(), _createBlock(_component_el_form_item, {
                                    key: 0,
                                    label: "是否签发提单"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_miezz_select, {
                                        modelValue: _ctx.form.Data.DeparturePortLadingBill,
                                        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.form.Data.DeparturePortLadingBill) = $event)),
                                        options: _ctx.yesno,
                                        boolean: true,
                                        clearable: false,
                                        disabled: 
                _ctx.logier?.CompanyType == _ctx.EnumCompanyType.Customer ||
                _ctx.form.Data.Status == _ctx.EnumOrderPoolStatus.Completed
              
                                      }, null, 8, ["modelValue", "options", "disabled"])
                                    ]),
                                    _: 1
                                  }))
                                : (_openBlock(), _createBlock(_component_el_form_item, { key: 1 })),
                              _createVNode(_component_el_checkbox, {
                                modelValue: _ctx.form.Data.DeparturePortTake,
                                "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.form.Data.DeparturePortTake) = $event)),
                                label: "提/送",
                                disabled: 
              _ctx.logier?.CompanyType == _ctx.EnumCompanyType.Customer ||
              _ctx.form.Data.Status == _ctx.EnumOrderPoolStatus.Completed
            
                              }, null, 8, ["modelValue", "disabled"]),
                              (_ctx.form.Data.DeparturePortTake)
                                ? (_openBlock(), _createBlock(_component_el_form_item, {
                                    key: 2,
                                    label: "方式",
                                    prop: "DeparturePortTakeMethod",
                                    rules: _ctx.form.Rules.DeparturePortTakeMethod
                                  }, {
                                    default: _withCtx(() => [
                                      (
                _ctx.form.Data.TransportContainerLoad ==
                _ctx.EnumTransportContainerLoad.FCL
              )
                                        ? (_openBlock(), _createBlock(_component_el_checkbox, {
                                            key: 0,
                                            label: "集装箱拖车",
                                            modelValue: _ctx.form.Data.DeparturePortContainer,
                                            "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.form.Data.DeparturePortContainer) = $event)),
                                            disabled: 
                _ctx.logier?.CompanyType == _ctx.EnumCompanyType.Customer ||
                _ctx.form.Data.Status == _ctx.EnumOrderPoolStatus.Completed
              
                                          }, null, 8, ["modelValue", "disabled"]))
                                        : _createCommentVNode("", true),
                                      _createVNode(_component_el_checkbox, {
                                        label: "干线物流",
                                        modelValue: _ctx.form.Data.DeparturePortTrunk,
                                        "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.form.Data.DeparturePortTrunk) = $event)),
                                        disabled: 
                _ctx.logier?.CompanyType == _ctx.EnumCompanyType.Customer ||
                _ctx.form.Data.Status == _ctx.EnumOrderPoolStatus.Completed
              
                                      }, null, 8, ["modelValue", "disabled"]),
                                      _createVNode(_component_el_checkbox, {
                                        label: "快递",
                                        modelValue: _ctx.form.Data.DeparturePortExpress,
                                        "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.form.Data.DeparturePortExpress) = $event)),
                                        disabled: 
                _ctx.logier?.CompanyType == _ctx.EnumCompanyType.Customer ||
                _ctx.form.Data.Status == _ctx.EnumOrderPoolStatus.Completed
              
                                      }, null, 8, ["modelValue", "disabled"])
                                    ]),
                                    _: 1
                                  }, 8, ["rules"]))
                                : (_openBlock(), _createBlock(_component_el_form_item, { key: 3 })),
                              _createVNode(_component_el_checkbox, {
                                modelValue: _ctx.form.Data.DeparturePortWarehouse,
                                "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.form.Data.DeparturePortWarehouse) = $event)),
                                label: "仓库/堆场/码头",
                                disabled: 
              _ctx.orderServiceType == 10 ||
              _ctx.logier?.CompanyType == _ctx.EnumCompanyType.Customer ||
              _ctx.form.Data.Status == _ctx.EnumOrderPoolStatus.Completed
            
                              }, null, 8, ["modelValue", "disabled"]),
                              _createVNode(_component_el_form_item),
                              _createVNode(_component_el_checkbox, {
                                modelValue: _ctx.form.Data.DeparturePortDeclareCustoms,
                                "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.form.Data.DeparturePortDeclareCustoms) = $event)),
                                label: "报关",
                                disabled: 
              _ctx.logier?.CompanyType == _ctx.EnumCompanyType.Customer ||
              _ctx.form.Data.Status == _ctx.EnumOrderPoolStatus.Completed
            
                              }, null, 8, ["modelValue", "disabled"]),
                              (
              _ctx.form.Data.DeparturePortDeclareCustoms &&
              _ctx.form.Data.TransportDirection != _ctx.EnumTransportDirection.Import
            )
                                ? (_openBlock(), _createBlock(_component_el_form_item, {
                                    key: 4,
                                    label: "是否退税"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_miezz_select, {
                                        modelValue: _ctx.form.Data.DeparturePortDrawback,
                                        "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.form.Data.DeparturePortDrawback) = $event)),
                                        options: _ctx.yesno,
                                        boolean: true,
                                        clearable: false,
                                        disabled: 
                _ctx.logier?.CompanyType == _ctx.EnumCompanyType.Customer ||
                _ctx.form.Data.Status == _ctx.EnumOrderPoolStatus.Completed
              
                                      }, null, 8, ["modelValue", "options", "disabled"])
                                    ]),
                                    _: 1
                                  }))
                                : (_openBlock(), _createBlock(_component_el_form_item, { key: 5 }))
                            ]),
                            _: 2
                          }, 1032, ["prop", "label"]))
                        : (
            item.Prop == 'DestinationPortClearCustoms' &&
            _ctx.form.Data.TransportDirection != _ctx.EnumTransportDirection.Other
          )
                          ? (_openBlock(), _createBlock(_component_el_form_item, {
                              key: 5,
                              prop: item.Prop,
                              label: item.Label,
                              class: "services-check-group"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_checkbox, {
                                  modelValue: _ctx.form.Data.DestinationPortClearCustoms,
                                  "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.form.Data.DestinationPortClearCustoms) = $event)),
                                  label: "清关",
                                  disabled: 
              _ctx.logier?.CompanyType == _ctx.EnumCompanyType.Customer ||
              _ctx.form.Data.Status == _ctx.EnumOrderPoolStatus.Completed
            
                                }, null, 8, ["modelValue", "disabled"]),
                                (_ctx.form.Data.DestinationPortClearCustoms)
                                  ? (_openBlock(), _createBlock(_component_el_form_item, {
                                      key: 0,
                                      label: "清关抬头"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_miezz_select, {
                                          modelValue: _ctx.form.Data.DestinationPortCustomsClearanceHeaderProvider,
                                          "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.form.Data.DestinationPortCustomsClearanceHeaderProvider) = $event)),
                                          options: _ctx.EnumCustomsClearanceHeaderProviderOption,
                                          clearable: false,
                                          disabled: 
                _ctx.logier?.CompanyType == _ctx.EnumCompanyType.Customer ||
                _ctx.form.Data.Status == _ctx.EnumOrderPoolStatus.Completed
              
                                        }, null, 8, ["modelValue", "options", "disabled"])
                                      ]),
                                      _: 1
                                    }))
                                  : (_openBlock(), _createBlock(_component_el_form_item, { key: 1 })),
                                (
              _ctx.form.Data.DestinationPortClearCustoms &&
              _ctx.form.Data.DestinationPortCustomsClearanceHeaderProvider ==
                _ctx.EnumCustomsClearanceHeaderProvider.ServiceProvider
            )
                                  ? (_openBlock(), _createBlock(_component_el_form_item, {
                                      key: 2,
                                      label: "是否出具税单",
                                      style: {"margin-left":"172px","margin-top":"5px"}
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_miezz_select, {
                                          modelValue: _ctx.form.Data.DestinationPortCustomsClearanceIssueTaxReceipt,
                                          "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.form.Data.DestinationPortCustomsClearanceIssueTaxReceipt) = $event)),
                                          options: _ctx.yesno,
                                          boolean: true,
                                          clearable: false,
                                          disabled: 
                _ctx.logier?.CompanyType == _ctx.EnumCompanyType.Customer ||
                _ctx.form.Data.Status == _ctx.EnumOrderPoolStatus.Completed
              
                                        }, null, 8, ["modelValue", "options", "disabled"])
                                      ]),
                                      _: 1
                                    }))
                                  : (_openBlock(), _createBlock(_component_el_form_item, {
                                      key: 3,
                                      style: {"margin-left":"172px"}
                                    })),
                                _createVNode(_component_el_checkbox, {
                                  modelValue: _ctx.form.Data.DestinationPortDelivery,
                                  "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.form.Data.DestinationPortDelivery) = $event)),
                                  label: "提/送",
                                  disabled: 
              _ctx.logier?.CompanyType == _ctx.EnumCompanyType.Customer ||
              _ctx.form.Data.Status == _ctx.EnumOrderPoolStatus.Completed
            
                                }, null, 8, ["modelValue", "disabled"]),
                                (_ctx.form.Data.DestinationPortDelivery)
                                  ? (_openBlock(), _createBlock(_component_el_form_item, {
                                      key: 4,
                                      prop: "DestinationPortDeliveryMethod",
                                      label: "方式"
                                    }, {
                                      default: _withCtx(() => [
                                        (
                _ctx.form.Data.TransportType != _ctx.EnumTransportType.Air &&
                _ctx.form.Data.TransportContainerLoad !=
                  _ctx.EnumTransportContainerLoad.Bulk &&
                (_ctx.form.Data.TransportContainerLoad !=
                  _ctx.EnumTransportContainerLoad.LCL ||
                  _ctx.form.Data.DeparturePortBooking)
              )
                                          ? (_openBlock(), _createBlock(_component_el_checkbox, {
                                              key: 0,
                                              label: "集装箱拖车",
                                              modelValue: _ctx.form.Data.DestinationPortContainer,
                                              "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.form.Data.DestinationPortContainer) = $event)),
                                              disabled: 
                _ctx.logier?.CompanyType == _ctx.EnumCompanyType.Customer ||
                _ctx.form.Data.Status == _ctx.EnumOrderPoolStatus.Completed
              
                                            }, null, 8, ["modelValue", "disabled"]))
                                          : _createCommentVNode("", true),
                                        _createVNode(_component_el_checkbox, {
                                          label: "干线物流",
                                          modelValue: _ctx.form.Data.DestinationPortTrunk,
                                          "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.form.Data.DestinationPortTrunk) = $event)),
                                          disabled: 
                _ctx.logier?.CompanyType == _ctx.EnumCompanyType.Customer ||
                _ctx.form.Data.Status == _ctx.EnumOrderPoolStatus.Completed
              
                                        }, null, 8, ["modelValue", "disabled"]),
                                        _createVNode(_component_el_checkbox, {
                                          label: "快递",
                                          modelValue: _ctx.form.Data.DestinationPortExpress,
                                          "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.form.Data.DestinationPortExpress) = $event)),
                                          disabled: 
                _ctx.logier?.CompanyType == _ctx.EnumCompanyType.Customer ||
                _ctx.form.Data.Status == _ctx.EnumOrderPoolStatus.Completed
              
                                        }, null, 8, ["modelValue", "disabled"])
                                      ]),
                                      _: 1
                                    }))
                                  : (_openBlock(), _createBlock(_component_el_form_item, { key: 5 })),
                                _createVNode(_component_el_checkbox, {
                                  modelValue: _ctx.form.Data.DestinationPortWarehouse,
                                  "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.form.Data.DestinationPortWarehouse) = $event)),
                                  label: "仓库/堆场/码头",
                                  disabled: 
              _ctx.orderServiceType == 20 ||
              _ctx.logier?.CompanyType == _ctx.EnumCompanyType.Customer ||
              _ctx.form.Data.Status == _ctx.EnumOrderPoolStatus.Completed
            
                                }, null, 8, ["modelValue", "disabled"])
                              ]),
                              _: 2
                            }, 1032, ["prop", "label"]))
                          : (
            item.Prop == 'ServiceRemark' &&
            _ctx.powers.contains('OrderManagement_Pool_Add_SaveShortcut') &&
            _ctx.form.Data.TransportDirection != _ctx.EnumTransportDirection.Other
          )
                            ? (_openBlock(), _createBlock(_component_el_form_item, {
                                key: 6,
                                prop: item.Prop,
                                label: item.Label
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_miezz_input, {
                                    modelValue: _ctx.form.Data.ServiceRemark,
                                    "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.form.Data.ServiceRemark) = $event)),
                                    valueKey: "Label",
                                    "fetch-suggestions": _ctx.getServiceRemarks,
                                    "trigger-on-focus": true,
                                    deleteOption: true,
                                    checkDeleteOption: (it) => it.Data,
                                    onHandleDeleteOption: _ctx.handleDeleteServiceRemark
                                  }, null, 8, ["modelValue", "fetch-suggestions", "checkDeleteOption", "onHandleDeleteOption"])
                                ]),
                                _: 2
                              }, 1032, ["prop", "label"]))
                            : (
            item.Prop == 'ServiceRemark' &&
            _ctx.form.Data.TransportDirection != _ctx.EnumTransportDirection.Other
          )
                              ? (_openBlock(), _createBlock(_component_el_form_item, {
                                  key: 7,
                                  prop: item.Prop,
                                  label: item.Label
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_miezz_select, {
                                      modelValue: _ctx.form.Data.ServiceRemark,
                                      "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.form.Data.ServiceRemark) = $event)),
                                      options: _ctx.serviceRemarks
                                    }, null, 8, ["modelValue", "options"])
                                  ]),
                                  _: 2
                                }, 1032, ["prop", "label"]))
                              : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["modelValue"]))
        : _createCommentVNode("", true)
    ], 4)), [
      [_directive_loading, _ctx.loading]
    ]),
    (
      !_ctx.id &&
      (!_ctx.form.Data ||
        _ctx.form.Data.TransportDirection != _ctx.EnumTransportDirection.Other)
    )
      ? (_openBlock(), _createBlock(_component_el_scrollbar, {
          key: 0,
          class: "services"
        }, {
          default: _withCtx(() => [
            (_ctx.services && _ctx.services.length > 0)
              ? (_openBlock(), _createBlock(_component_vuedraggable, {
                  key: 0,
                  modelValue: _ctx.services,
                  "onUpdate:modelValue": _cache[28] || (_cache[28] = ($event: any) => ((_ctx.services) = $event)),
                  animation: "300",
                  handle: ".drag-sort-shortcut",
                  onEnd: _ctx.hnadleDrop
                }, {
                  item: _withCtx(({ element }) => [
                    element
                      ? (_openBlock(), _createBlock(_component_el_alert, {
                          key: 0,
                          type: 
            _ctx.form.Data &&
            element.Data &&
            _ctx.form.Data.PriceServiceShortcutId &&
            _ctx.form.Data.PriceServiceShortcutId == element.Value
              ? 'success'
              : 'info'
          ,
                          closable: false,
                          onClick: (e) => _ctx.handleServiceClick(e, element)
                        }, {
                          title: _withCtx(() => [
                            _createElementVNode("div", _hoisted_1, [
                              _createVNode(_component_el_tooltip, {
                                effect: "dark",
                                content: element.Label,
                                placement: "top",
                                "show-after": 500
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(element.Label), 1)
                                ]),
                                _: 2
                              }, 1032, ["content"])
                            ])
                          ]),
                          default: _withCtx(() => [
                            (
              _ctx.powers && _ctx.powers.contains('OrderManagement_Pool_Add_SaveShortcut')
            )
                              ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.shortcutButtons, (btn, i) => {
                                    return (_openBlock(), _createElementBlock("span", { key: i }, [
                                      (
                  element.Data &&
                  (btn.Code != 'delete-shortcut' || !element.Data.ServiceId)
                )
                                        ? (_openBlock(), _createBlock(_component_miezz_button, {
                                            key: 0,
                                            model: btn,
                                            onHandleClick: 
                  (arg1, arg2) => _ctx.handleRowButtonClick(arg1, element, arg2)
                
                                          }, null, 8, ["model", "onHandleClick"]))
                                        : _createCommentVNode("", true)
                                    ]))
                                  }), 128))
                                ]))
                              : _createCommentVNode("", true)
                          ]),
                          _: 2
                        }, 1032, ["type", "onClick"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["modelValue", "onEnd"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_miezz_modal, {
      modelValue: _ctx.modalShortcut,
      "onUpdate:modelValue": _cache[30] || (_cache[30] = ($event: any) => ((_ctx.modalShortcut) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_miezz_form, {
          ref: "form-shortcut",
          modelValue: _ctx.formShortcut,
          "onUpdate:modelValue": _cache[29] || (_cache[29] = ($event: any) => ((_ctx.formShortcut) = $event))
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}