import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5b42d90e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "right" }
const _hoisted_2 = {
  ref: "CountTotalNumber",
  class: "count"
}
const _hoisted_3 = {
  ref: "CountCustomerNumberPie",
  class: "count"
}
const _hoisted_4 = {
  key: 0,
  ref: "CountCustomerNumberBar",
  class: "count"
}
const _hoisted_5 = {
  key: 1,
  ref: "CountCustomerServiceNumberPie",
  class: "count"
}
const _hoisted_6 = {
  ref: "CountServiceNumberPie",
  class: "count"
}
const _hoisted_7 = {
  key: 0,
  ref: "CountServiceNumberBar",
  class: "count"
}
const _hoisted_8 = {
  key: 1,
  ref: "CountServiceCustomerNumberPie",
  class: "count"
}
const _hoisted_9 = {
  key: 0,
  ref: "CountCustomNumber",
  class: "count"
}
const _hoisted_10 = {
  key: 1,
  ref: "CountCustomGoodsWeightBar",
  class: "count",
  style: {"width":"50%"}
}
const _hoisted_11 = {
  key: 2,
  ref: "CountCustomGoodsVolumeBar",
  class: "count",
  style: {"width":"50%"}
}
const _hoisted_12 = {
  ref: "CountTotalGoodsWeightBar",
  class: "count",
  style: {"width":"50%"}
}
const _hoisted_13 = {
  ref: "CountTotalGoodsVolumeBar",
  class: "count",
  style: {"width":"50%"}
}
const _hoisted_14 = {
  ref: "CountCustomerGoodsWeightPie",
  class: "count",
  style: {"width":"50%"}
}
const _hoisted_15 = {
  ref: "CountCustomerGoodsVolumePie",
  class: "count",
  style: {"width":"50%"}
}
const _hoisted_16 = {
  key: 0,
  ref: "CountCustomerGoodsWeightBar",
  class: "count",
  style: {"width":"50%"}
}
const _hoisted_17 = {
  key: 1,
  ref: "CountCustomerGoodsVolumeBar",
  class: "count",
  style: {"width":"50%"}
}
const _hoisted_18 = {
  key: 2,
  ref: "CountCustomerServiceGoodsWeightPie",
  class: "count",
  style: {"width":"50%"}
}
const _hoisted_19 = {
  key: 3,
  ref: "CountCustomerServiceGoodsVolumePie",
  class: "count",
  style: {"width":"50%"}
}
const _hoisted_20 = {
  ref: "CountServiceGoodsWeightPie",
  class: "count",
  style: {"width":"50%"}
}
const _hoisted_21 = {
  ref: "CountServiceGoodsVolumePie",
  class: "count",
  style: {"width":"50%"}
}
const _hoisted_22 = {
  key: 0,
  ref: "CountServiceGoodsWeightBar",
  class: "count",
  style: {"width":"50%"}
}
const _hoisted_23 = {
  key: 1,
  ref: "CountServiceGoodsVolumeBar",
  class: "count",
  style: {"width":"50%"}
}
const _hoisted_24 = {
  key: 2,
  ref: "CountServiceCustomerGoodsWeightPie",
  class: "count",
  style: {"width":"50%"}
}
const _hoisted_25 = {
  key: 3,
  ref: "CountServiceCustomerGoodsVolumePie",
  class: "count",
  style: {"width":"50%"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_alert = _resolveComponent("el-alert")!
  const _component_el_card = _resolveComponent("el-card")!
  const _component_miezz_month_choose = _resolveComponent("miezz-month-choose")!
  const _component_miezz_card = _resolveComponent("miezz-card")!
  const _component_miezz_select = _resolveComponent("miezz-select")!
  const _component_el_radio_button = _resolveComponent("el-radio-button")!
  const _component_el_radio_group = _resolveComponent("el-radio-group")!
  const _component_miezz_dynamic_search = _resolveComponent("miezz-dynamic-search")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_card, { class: "left" }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.types, (item, i) => {
          return (_openBlock(), _createBlock(_component_el_alert, {
            key: i,
            title: item.title,
            type: item.type,
            effect: "dark",
            closable: false,
            center: item.type == 'success',
            class: _normalizeClass(item.code == _ctx.type ? 'active' : ''),
            onClick: ($event: any) => (_ctx.handleChooseType(item.code))
          }, null, 8, ["title", "type", "center", "class", "onClick"]))
        }), 128))
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_miezz_month_choose, {
        modelValue: _ctx.month,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.month) = $event))
      }, null, 8, ["modelValue"]),
      (_ctx.type == 'CountTotalNumber')
        ? (_openBlock(), _createBlock(_component_miezz_card, { key: 0 }, {
            default: _withCtx(() => [
              _createVNode(_component_el_alert, {
                title: "统计数据每10分钟更新一次，不包含未关联订单的委托书数据",
                type: "info",
                effect: "light",
                closable: false
              }),
              _createElementVNode("div", _hoisted_2, null, 512)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.type == 'CountCustomerNumber')
        ? (_openBlock(), _createBlock(_component_miezz_card, { key: 1 }, {
            default: _withCtx(() => [
              _createVNode(_component_el_alert, {
                title: "统计数据每10分钟更新一次，不包含未关联订单的委托书数据",
                type: "info",
                effect: "light",
                closable: false
              }),
              _createElementVNode("div", _hoisted_3, null, 512)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.type == 'CountCustomerNumber')
        ? (_openBlock(), _createBlock(_component_miezz_card, { key: 2 }, {
            default: _withCtx(() => [
              _createElementVNode("div", null, [
                _createVNode(_component_miezz_select, {
                  modelValue: _ctx.companyId,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.companyId) = $event)),
                  options: _ctx.companies,
                  labelKey: "NameZh/NameEn",
                  valueKey: "Id",
                  className: "choose",
                  onHandleChange: _ctx.handleChart
                }, null, 8, ["modelValue", "options", "onHandleChange"]),
                _createVNode(_component_el_radio_group, {
                  modelValue: _ctx.tab,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.tab) = $event)),
                  size: "small",
                  onChange: _ctx.handleChart
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_radio_button, { label: "Number" }, {
                      default: _withCtx(() => [
                        _createTextVNode("票数合计")
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_radio_button, { label: "Service" }, {
                      default: _withCtx(() => [
                        _createTextVNode("服务组成")
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["modelValue", "onChange"])
              ]),
              (_ctx.tab == 'Number')
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, null, 512))
                : _createCommentVNode("", true),
              (_ctx.tab == 'Service')
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, null, 512))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.type == 'CountServiceNumber')
        ? (_openBlock(), _createBlock(_component_miezz_card, { key: 3 }, {
            default: _withCtx(() => [
              _createVNode(_component_el_alert, {
                title: "统计数据每10分钟更新一次，不包含未关联订单的委托书数据",
                type: "info",
                effect: "light",
                closable: false
              }),
              _createElementVNode("div", _hoisted_6, null, 512)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.type == 'CountServiceNumber')
        ? (_openBlock(), _createBlock(_component_miezz_card, { key: 4 }, {
            default: _withCtx(() => [
              _createElementVNode("div", null, [
                _createVNode(_component_miezz_select, {
                  modelValue: _ctx.service,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.service) = $event)),
                  options: _ctx.services,
                  className: "choose",
                  onHandleChange: _ctx.handleChart
                }, null, 8, ["modelValue", "options", "onHandleChange"]),
                _createVNode(_component_el_radio_group, {
                  modelValue: _ctx.tab,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.tab) = $event)),
                  size: "small",
                  onChange: _ctx.handleChart
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_radio_button, { label: "Number" }, {
                      default: _withCtx(() => [
                        _createTextVNode("票数合计")
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_radio_button, { label: "Customer" }, {
                      default: _withCtx(() => [
                        _createTextVNode("客户组成")
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["modelValue", "onChange"])
              ]),
              (_ctx.tab == 'Number')
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, null, 512))
                : _createCommentVNode("", true),
              (_ctx.tab == 'Customer')
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, null, 512))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.type == 'CountCustomNumber' || _ctx.type == 'CountCustomGoods')
        ? (_openBlock(), _createBlock(_component_miezz_card, { key: 5 }, {
            default: _withCtx(() => [
              _createVNode(_component_miezz_dynamic_search, {
                ref: "miezz-dynamic-search",
                modelValue: _ctx.dto.DynamicSearchId,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.dto.DynamicSearchId) = $event)),
                items: _ctx.searchs,
                simple: false,
                fixProp: false,
                hideCompare: false,
                histories: _ctx.histories,
                code: "Count_Pool",
                onHandleSearch: _ctx.handleChart,
                onHandleGetHistories: _ctx.handleGetHistories
              }, {
                "dynamic-search-customize": _withCtx(({ model }) => [
                  (model.Where.Prop == 'DynamicSearch_LoadingPortId')
                    ? (_openBlock(), _createBlock(_component_miezz_select, {
                        key: 0,
                        modelValue: model.Where.Value,
                        "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                        options: _ctx.loadingPorts,
                        clearable: ""
                      }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
                    : (model.Where.Prop == 'DynamicSearch_DeliveryPortId')
                      ? (_openBlock(), _createBlock(_component_miezz_select, {
                          key: 1,
                          modelValue: model.Where.Value,
                          "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                          options: _ctx.deliveryPorts,
                          clearable: ""
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
                      : (model.Where.Prop == 'DynamicSearch_DeliveryPlaceLabel')
                        ? (_openBlock(), _createBlock(_component_miezz_select, {
                            key: 2,
                            modelValue: model.Where.Value,
                            "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                            options: _ctx.deliveryPlaces,
                            clearable: ""
                          }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
                        : (model.Where.Prop == 'DynamicSearch_ShipName')
                          ? (_openBlock(), _createBlock(_component_miezz_select, {
                              key: 3,
                              modelValue: model.Where.Value,
                              "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                              options: _ctx.shipNames,
                              clearable: ""
                            }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
                          : (model.Where.Prop == 'DynamicSearch_VoyageNumber')
                            ? (_openBlock(), _createBlock(_component_miezz_select, {
                                key: 4,
                                modelValue: model.Where.Value,
                                "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                                options: _ctx.voyageNumbers,
                                clearable: ""
                              }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
                            : (model.Where.Prop == 'DynamicSearch_Flight')
                              ? (_openBlock(), _createBlock(_component_miezz_select, {
                                  key: 5,
                                  modelValue: model.Where.Value,
                                  "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                                  options: _ctx.flights,
                                  clearable: ""
                                }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
                              : (model.Where.Prop == 'DynamicSearch_RailwayNumber')
                                ? (_openBlock(), _createBlock(_component_miezz_select, {
                                    key: 6,
                                    modelValue: model.Where.Value,
                                    "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                                    options: _ctx.railwayNumbers,
                                    clearable: ""
                                  }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
                                : (model.Where.Prop == 'DynamicSearch_CarrierId')
                                  ? (_openBlock(), _createBlock(_component_miezz_select, {
                                      key: 7,
                                      modelValue: model.Where.Value,
                                      "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                                      options: _ctx.carriers,
                                      clearable: ""
                                    }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
                                  : (model.Where.Prop == 'DynamicSearch_ContainerType')
                                    ? (_openBlock(), _createBlock(_component_miezz_select, {
                                        key: 8,
                                        modelValue: model.Where.Value,
                                        "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                                        options: _ctx.containerTypes,
                                        clearable: ""
                                      }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
                                    : (model.Where.Prop == 'DynamicSearch_ClientUserId')
                                      ? (_openBlock(), _createBlock(_component_miezz_select, {
                                          key: 9,
                                          modelValue: model.Where.Value,
                                          "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                                          options: _ctx.clientUsers,
                                          clearable: ""
                                        }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
                                      : (model.Where.Prop == 'DynamicSearch_CustomerServiceId')
                                        ? (_openBlock(), _createBlock(_component_miezz_select, {
                                            key: 10,
                                            modelValue: model.Where.Value,
                                            "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                                            options: _ctx.customerServices,
                                            clearable: ""
                                          }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
                                        : (model.Where.Prop == 'DynamicSearch_TransportDirection')
                                          ? (_openBlock(), _createBlock(_component_miezz_select, {
                                              key: 11,
                                              modelValue: model.Where.Value,
                                              "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                                              options: _ctx.EnumTransportDirectionOption,
                                              number: true,
                                              clearable: ""
                                            }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
                                          : (model.Where.Prop == 'DynamicSearch_TransportType')
                                            ? (_openBlock(), _createBlock(_component_miezz_select, {
                                                key: 12,
                                                modelValue: model.Where.Value,
                                                "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                                                options: _ctx.EnumTransportTypeOption,
                                                number: true,
                                                clearable: ""
                                              }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
                                            : (
                model.Where.Prop == 'DynamicSearch_TransportContainerLoad'
              )
                                              ? (_openBlock(), _createBlock(_component_miezz_select, {
                                                  key: 13,
                                                  modelValue: model.Where.Value,
                                                  "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                                                  options: _ctx.EnumTransportContainerLoadOption,
                                                  number: true,
                                                  clearable: ""
                                                }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
                                              : (model.Where.Prop == 'DynamicSearch_ServiceRemark')
                                                ? (_openBlock(), _createBlock(_component_miezz_select, {
                                                    key: 14,
                                                    modelValue: model.Where.Value,
                                                    "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                                                    options: _ctx.services,
                                                    clearable: ""
                                                  }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
                                                : (model.Where.Prop == 'DynamicSearch_SalesmanId')
                                                  ? (_openBlock(), _createBlock(_component_miezz_select, {
                                                      key: 15,
                                                      modelValue: model.Where.Value,
                                                      "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                                                      options: _ctx.searchSalesmans,
                                                      clearable: ""
                                                    }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
                                                  : (model.Where.Prop == 'DynamicSearch_Source')
                                                    ? (_openBlock(), _createBlock(_component_miezz_select, {
                                                        key: 16,
                                                        modelValue: model.Where.Value,
                                                        "onUpdate:modelValue": ($event: any) => ((model.Where.Value) = $event),
                                                        options: _ctx.sources,
                                                        clearable: ""
                                                      }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
                                                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["modelValue", "items", "histories", "onHandleSearch", "onHandleGetHistories"]),
              (_ctx.type == 'CountCustomNumber')
                ? (_openBlock(), _createElementBlock("div", _hoisted_9, null, 512))
                : _createCommentVNode("", true),
              (_ctx.type == 'CountCustomGoods')
                ? (_openBlock(), _createElementBlock("div", _hoisted_10, null, 512))
                : _createCommentVNode("", true),
              (_ctx.type == 'CountCustomGoods')
                ? (_openBlock(), _createElementBlock("div", _hoisted_11, null, 512))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.type == 'CountTotalGoods')
        ? (_openBlock(), _createBlock(_component_miezz_card, { key: 6 }, {
            default: _withCtx(() => [
              _createVNode(_component_el_alert, {
                title: "统计数据每10分钟更新一次，不包含未关联订单的委托书数据",
                type: "info",
                effect: "light",
                closable: false
              }),
              _createElementVNode("div", _hoisted_12, null, 512),
              _createElementVNode("div", _hoisted_13, null, 512)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.type == 'CountCustomerGoods')
        ? (_openBlock(), _createBlock(_component_miezz_card, { key: 7 }, {
            default: _withCtx(() => [
              _createVNode(_component_el_alert, {
                title: "统计数据每10分钟更新一次，不包含未关联订单的委托书数据",
                type: "info",
                effect: "light",
                closable: false
              }),
              _createElementVNode("div", _hoisted_14, null, 512),
              _createElementVNode("div", _hoisted_15, null, 512)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.type == 'CountCustomerGoods')
        ? (_openBlock(), _createBlock(_component_miezz_card, { key: 8 }, {
            default: _withCtx(() => [
              _createElementVNode("div", null, [
                _createVNode(_component_miezz_select, {
                  modelValue: _ctx.companyId,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.companyId) = $event)),
                  options: _ctx.companies,
                  labelKey: "NameZh/NameEn",
                  valueKey: "Id",
                  className: "choose",
                  onHandleChange: _ctx.handleChart
                }, null, 8, ["modelValue", "options", "onHandleChange"]),
                _createVNode(_component_el_radio_group, {
                  modelValue: _ctx.tab,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.tab) = $event)),
                  size: "small",
                  onChange: _ctx.handleChart
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_radio_button, { label: "Number" }, {
                      default: _withCtx(() => [
                        _createTextVNode("货量合计")
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_radio_button, { label: "Service" }, {
                      default: _withCtx(() => [
                        _createTextVNode("服务组成")
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["modelValue", "onChange"])
              ]),
              (_ctx.tab == 'Number')
                ? (_openBlock(), _createElementBlock("div", _hoisted_16, null, 512))
                : _createCommentVNode("", true),
              (_ctx.tab == 'Number')
                ? (_openBlock(), _createElementBlock("div", _hoisted_17, null, 512))
                : _createCommentVNode("", true),
              (_ctx.tab == 'Service')
                ? (_openBlock(), _createElementBlock("div", _hoisted_18, null, 512))
                : _createCommentVNode("", true),
              (_ctx.tab == 'Service')
                ? (_openBlock(), _createElementBlock("div", _hoisted_19, null, 512))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.type == 'CountServiceGoods')
        ? (_openBlock(), _createBlock(_component_miezz_card, { key: 9 }, {
            default: _withCtx(() => [
              _createVNode(_component_el_alert, {
                title: "统计数据每10分钟更新一次，不包含未关联订单的委托书数据",
                type: "info",
                effect: "light",
                closable: false
              }),
              _createElementVNode("div", _hoisted_20, null, 512),
              _createElementVNode("div", _hoisted_21, null, 512)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.type == 'CountServiceGoods')
        ? (_openBlock(), _createBlock(_component_miezz_card, { key: 10 }, {
            default: _withCtx(() => [
              _createElementVNode("div", null, [
                _createVNode(_component_miezz_select, {
                  modelValue: _ctx.service,
                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.service) = $event)),
                  options: _ctx.services,
                  className: "choose",
                  onHandleChange: _ctx.handleChart
                }, null, 8, ["modelValue", "options", "onHandleChange"]),
                _createVNode(_component_el_radio_group, {
                  modelValue: _ctx.tab,
                  "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.tab) = $event)),
                  size: "small",
                  onChange: _ctx.handleChart
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_radio_button, { label: "Number" }, {
                      default: _withCtx(() => [
                        _createTextVNode("货量合计")
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_radio_button, { label: "Customer" }, {
                      default: _withCtx(() => [
                        _createTextVNode("客户组成")
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["modelValue", "onChange"])
              ]),
              (_ctx.tab == 'Number')
                ? (_openBlock(), _createElementBlock("div", _hoisted_22, null, 512))
                : _createCommentVNode("", true),
              (_ctx.tab == 'Number')
                ? (_openBlock(), _createElementBlock("div", _hoisted_23, null, 512))
                : _createCommentVNode("", true),
              (_ctx.tab == 'Customer')
                ? (_openBlock(), _createElementBlock("div", _hoisted_24, null, 512))
                : _createCommentVNode("", true),
              (_ctx.tab == 'Customer')
                ? (_openBlock(), _createElementBlock("div", _hoisted_25, null, 512))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ])
  ]))
}