import UiListItem from "./UiListItem";
import MiezzListItem from "@/models/MiezzListItem";
import { EnumPipe } from "@/models/EnumPipe";
import DtoListItemPriceQuotationSheet_Pool from "./DtoListItemPriceQuotationSheet_Pool";
import DtoListItemPriceQuotationSheet_Company from "./DtoListItemPriceQuotationSheet_Company";
import DtoListItemPriceQuotationSheet_Scheme from "./DtoListItemPriceQuotationSheet_Scheme";
import { EnumPriceQuotationSheetStage, EnumPriceQuotationSheetStageOption } from "./EnumPriceQuotationSheetStage";
import { EnumPriceQuotationSheetStatus, EnumPriceQuotationSheetStatusOption } from "./EnumPriceQuotationSheetStatus";

/**
 * 报价表分页列表-项
 * @description 自动生成的代码,请勿修改
 */
export default class DtoListItemPriceQuotationSheet extends UiListItem<string> {
    constructor() {
        //无参构造函数
        super()
        this.Schemes = [];
        this.Stage = EnumPriceQuotationSheetStage.Find;
    }

    [proName: string]: any;
    /**
     * 编号
     */
    Code?: string;
    /**
     * 委托书Id
     */
    PoolId?: string;
    /**
     * 委托书
     */
    Pool?: DtoListItemPriceQuotationSheet_Pool;
    /**
     * Booking No
     */
    BookingNo?: string;
    /**
     * 客户企业Id
     */
    CompanyId?: string;
    /**
     * 客户企业
     */
    Company?: DtoListItemPriceQuotationSheet_Company;
    /**
     * 联系人Id
     */
    UserId?: string;
    /**
     * 客户企业
     */
    CompanyLabel?: string;
    /**
     * 询盘人
     */
    UserLabel?: string;
    /**
     * 业务员
     */
    SalesmanLabel?: string;
    /**
     * 询盘描述
     */
    InquiryDescription?: string;
    /**
     * 追踪更新时间
     */
    TrackRecordUpdateDate?: Date;
    /**
     * 报价人
     */
    CreatedUserName?: string;
    /**
     * 创建人手机号码
     */
    CreatedPhoneNumber?: string;
    /**
     * 方案
     */
    Schemes?: DtoListItemPriceQuotationSheet_Scheme[];
    /**
     * 成本维护人
     */
    InfoCollectorLabel?: string;
    /**
     * 创建时间
     */
    CreatedTime?: Date;
    /**
     * 阶段
     */
    Stage?: EnumPriceQuotationSheetStage;
    /**
     * 状态
     */
    Status?: EnumPriceQuotationSheetStatus;
    /**
     * 更新时间
     */
    ChangeContentTime?: Date;
}

/**
 * 报价表分页列表-项-表单校验
 */
export const DtoListItemPriceQuotationSheetRules = {
}

/**
 * 报价表分页列表-项-设置项
 */
export const DtoListItemPriceQuotationSheetMiezzListItems: MiezzListItem[]  = [
  {
    Prop: "Code",
    Label: "编号",
  },
  {
    Prop: "BookingNo",
    Label: "Booking No",
    Customize: true,
  },
  {
    Prop: "CompanyLabel",
    Label: "客户企业",
  },
  {
    Prop: "UserLabel",
    Label: "询盘人",
  },
  {
    Prop: "SalesmanLabel",
    Label: "业务员",
  },
  {
    Prop: "InquiryDescription",
    Label: "询盘描述",
    Customize: true,
  },
  {
    Prop: "TrackRecordUpdateDate",
    Label: "追踪更新时间",
    Pipe: EnumPipe.Date,
  },
  {
    Prop: "CreatedUserName",
    Label: "报价人",
  },
  {
    Prop: "InfoCollectorLabel",
    Label: "成本维护人",
  },
  {
    Prop: "CreatedTime",
    Label: "创建时间",
    MaxWidth: "150px",
    Pipe: EnumPipe.Time,
  },
  {
    Prop: "Stage",
    Label: "阶段",
    Customize: true,
  },
  {
    Prop: "Status",
    Label: "状态",
    Pipe: EnumPipe.Enum,
    Options: EnumPriceQuotationSheetStatusOption,
  },
]
