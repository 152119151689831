
    import OSS from "ali-oss";
    import { Vue, Options } from "vue-class-component";
    import { Emit, Model, Prop, Watch } from "vue-property-decorator";
    import { ElMessage } from "element-plus";
    import { ElMessageBox } from "element-plus";
    import MiezzButton from "@/models/MiezzButton";
    import MiezzForm from "@/models/MiezzForm";
    import OrderWarehouseAppService from "@/apis.machine/OrderWarehouseAppService";
    import DtoFormOrderWarehouse, {
        DtoFormOrderWarehouseMiezzFormItems,
        DtoFormOrderWarehouseRules,
    } from "@/models.machine/DtoFormOrderWarehouse";
    import UiSelectOption from "@/models.machine/UiSelectOption";
    import ParamCurrencySystemAppService from "@/apis.machine/ParamCurrencySystemAppService";
    import DtoFormOrderWarehousePackageShape, {
        DtoFormOrderWarehousePackageShareMiezzFormItems,
        DtoFormOrderWarehousePackageShareRules,
    } from "@/models.machine/DtoFormOrderWarehousePackageShare";
    import DtoFormOrderWarehousePackageSpec, {
        DtoFormOrderWarehousePackageSpecMiezzFormItems,
        DtoFormOrderWarehousePackageSpecRules,
    } from "@/models.machine/DtoFormOrderWarehousePackageSpec";
    import ProductAppService from "@/apis.machine/ProductAppService";
    import AliyunOSS from "@/apis/AliyunOSS";
    import ParamChargeUnitAppService from "@/apis.machine/ParamChargeUnitAppService";


    export default class OrderWarehouseForm extends Vue {
        client?: OSS;
        isLoading = false;
        id = "";
        //类型：20销售，10采购
        type = "";
        //币制
        currencys: UiSelectOption<string>[] = [];

        packageIndex = -1;
        specIndex = -1;
        shipmentIndex = -1;

        imageRuleForm = {
            visiable: false,
            row: undefined,
        };
        partners?: UiSelectOption<string>[] = [];
        addresss?: string[];
        requirements?: string[];
        shipmentRuleForm = {
            visiable: false,
            number: 1,
            rows: [{ Type: 0, Value: "" }],
        };

        form = new MiezzForm<DtoFormOrderWarehouse, string>();

        formTemp = new MiezzForm<DtoFormOrderWarehouse, string>();
        bottomButtons?: MiezzButton[] = [
            {
                Code: "back",
                Label: "返回",
                MiezzIcon: "back",
                Type: "default",
                Size: "small",
            },
            {
                Code: "submit",
                Label: "保存",
                Icon: "select",
                Type: "primary",
                Size: "small",
            },
            {
                Code: "continue",
                Label: "继续添加",
                Icon: "plus",
                Type: "primary",
                Size: "small",
                Hide: true,
            },
            {
                Code: "delivery",
                Label: "全部交货",
                Icon: "select",
                Type: "primary",
                Size: "small",
                Hide: true,
            },
        ];
        shapeBottomButtons?: MiezzButton[] = [
            {
                IsBlue: true,
                Code: "submit",
                Label: "保存",
                Type: "primary",
                Size: "small",
            },
            {
                IsBlue: true,
                Code: "close",
                Label: "关闭",
                Type: "default",
                Size: "small",
            },
        ];

        shipmentBottomButtons?: MiezzButton[] = [
            {
                IsBlue: true,
                Code: "submit",
                Label: "保存",
                Type: "primary",
                Size: "small",
            },
            {
                IsBlue: true,
                Code: "close",
                Label: "关闭",
                Type: "default",
                Size: "small",
            },
        ];

        specBottomButtons?: MiezzButton[] = [
            {
                IsBlue: true,
                Code: "submit",
                Label: "保存",
                Type: "primary",
                Size: "small",
            },
            {
                IsBlue: true,
                Code: "close",
                Label: "关闭",
                Type: "default",
                Size: "small",
            },
        ];

        imageBottomButtons?: MiezzButton[] = [
            {
                IsBlue: true,
                Code: "close",
                Label: "关闭",
                Type: "default",
                Size: "small",
            },
        ];

        chargeUnits: UiSelectOption<string>[] = [];
        shapeRuleForm = new MiezzForm<DtoFormOrderWarehousePackageShape, string>();
        specRuleForm = new MiezzForm<DtoFormOrderWarehousePackageSpec, string>();

        created(): void {
            this.client = AliyunOSS.GetClient();
            this.id = this.$route.params.id as string;
            this.type = this.$route.params.type as string;
            //表单-数据-初始化
            this.form.Data = new DtoFormOrderWarehouse();
            //表单-校验
            this.form.Rules = DtoFormOrderWarehouseRules;
            //表单-元素配置
            this.form.Items = DtoFormOrderWarehouseMiezzFormItems;

            this.shapeRuleForm.Data = new DtoFormOrderWarehousePackageShape();
            this.shapeRuleForm.Data.visible = false;

            //表单-校验
            this.shapeRuleForm.Rules = DtoFormOrderWarehousePackageShareRules;
            //表单-元素配置
            this.shapeRuleForm.Items = DtoFormOrderWarehousePackageShareMiezzFormItems;

            this.specRuleForm.Data = new DtoFormOrderWarehousePackageSpec();
            this.specRuleForm.Data.visible = false;
            //表单-校验
            this.specRuleForm.Rules = DtoFormOrderWarehousePackageSpecRules;
            //表单-元素配置
            this.specRuleForm.Items = DtoFormOrderWarehousePackageSpecMiezzFormItems;
            this.specRuleForm.Data.ChargeUnit = "";

            //修改元素配置
            //for (const item of this.form.Items) {
            //  switch (item.Prop) {
            //    default:
            //      break;
            //  }
            //}
            ParamChargeUnitAppService.GetSelect().then((r) => {
                this.chargeUnits = r.data.Data ?? [];
            });
            this.onRule();
            this.handleGet();
        }

        onDeletePartner(id: string, index: number) {
            var value = this.form.Data.Partner;
            OrderWarehouseAppService.DeletePartnerById(id).then((r) => {
                if (this.partners) {
                    var oldValue = "";
                    if (this.partners) {
                        oldValue = this.partners[index].Label as string;
                    }

                    if (this.partners[index].Label == this.form.Data.Partner) {
                        this.form.Data.Partner = undefined;
                    }
                    this.partners?.splice(index, 1);

                    if (oldValue != value) {
                        this.form.Data.Partner = value;
                    }
                    else {
                        this.form.Data.Partner = "";
                    }
                    ElMessage.success("历史记录已删除");
                }
            })

        }


        querySearchPartnerAsync(queryString: string, cb: any) {
            var restaurants = this.partners;
            if (!restaurants) {
                restaurants = [];
            }
            var results = [];
            if (queryString != null && queryString && queryString != "null" && queryString != '' && this.partners) {
                queryString = queryString.toLowerCase();
                var number = 0;
                for (var it of this.partners) {
                    if (it) {
                        var Name = it.toLowerCase();
                        if (Name.indexOf(queryString) >= 0 && number < 30) {
                            results.push(it);
                            number++;
                        }
                    }
                }
            }
            else {
                if (this.partners) {
                    for (var i = 0; i < this.partners.length; i++) {
                        results.push(this.partners[i])
                    }
                }
            }
            cb(results);
        }
        handleSelectPartner(item: string) {
            this.form.Data.Partner = item;
            MiezzForm.clearValidate(this.$refs, "ruleForm", "Partner");

            var historyParam = {
                Type: Number(this.type),
                Partner: this.form.Data.Partner,
            };
            OrderWarehouseAppService.GetAddressHistory(historyParam).then((hi) => {
                if (hi.data.Data) this.addresss = hi.data.Data;
                if (hi && hi.data.Data && hi.data.Data.length == 1) {
                    this.form.Data.Address = hi.data.Data[0];
                }
            })

            //OrderWarehouseAppService.GetRequirementHistory(historyParam).then((hi) => {
            //    if (hi.data.Data) this.requirements = hi.data.Data;
            //    if (hi && hi.data.Data && hi.data.Data.length == 1) {
            //        this.form.Data.Requirement = hi.data.Data[0];
            //    }
            //})
        }

        querySearchAddressAsync(queryString: string, cb: any) {
            var restaurants = this.addresss;
            if (!restaurants) {
                restaurants = [];
            }
            var results = [];
            if (queryString != null && queryString && queryString != "null" && queryString != '' && this.addresss) {
                queryString = queryString.toLowerCase();
                var number = 0;
                for (var it of this.addresss) {
                    if (it) {
                        var Name = it.toLowerCase();
                        if (Name.indexOf(queryString) >= 0 && number < 30) {
                            results.push(it);
                            number++;
                        }
                    }
                }
            }
            else {
                if (this.addresss) {
                    for (var i = 0; i < this.addresss.length; i++) {
                        results.push(this.addresss[i])
                    }
                }
            }
            cb(results);
        }
        handleSelectAddress(item: string) {
            this.form.Data.Address = item;
            MiezzForm.clearValidate(this.$refs, "ruleForm", "Address");
        }

        querySearchRequirementAsync(queryString: string, cb: any) {
            var restaurants = this.requirements;
            if (!restaurants) {
                restaurants = [];
            }
            var results = [];
            if (queryString != null && queryString && queryString != "null" && queryString != '' && this.requirements) {
                queryString = queryString.toLowerCase();
                var number = 0;
                for (var it of this.requirements) {
                    if (it) {
                        var Name = it.toLowerCase();
                        if (Name.indexOf(queryString) >= 0 && number < 30) {
                            results.push(it);
                            number++;
                        }
                    }
                }
            }
            else {
                if (this.requirements) {
                    for (var i = 0; i < this.requirements.length; i++) {
                        results.push(this.requirements[i])
                    }
                }
            }
            cb(results);
        }
        handleSelectRequirement(item: string) {
            this.form.Data.Requirement = item;
            MiezzForm.clearValidate(this.$refs, "ruleForm", "Requirement");
        }

        onChangeCurrencyId(): void {
            this.form.Data.CurrencySystemName = "";
            for (var it of this.currencys) {
                if (this.form.Data.CurrencySystemId == it.Value) {
                    this.form.Data.CurrencySystemName = it.Data.Code;
                }
            }
        }


        onChangeShipment(): void {
            if (this.shipmentRuleForm && this.shipmentRuleForm.rows && this.shipmentRuleForm.rows.length > 0) {
                var pk = this.shipmentRuleForm;
                for (var it of pk.rows) {
                    if (it.Value && it.Value != "") {
                        it.Value = TrimSpace(it.Value as string);
                    }
                }
            }
        }

        onShipment(index: any) {
            this.shipmentIndex = index;

            if (this.shipmentRuleForm && this.form.Data && this.form.Data.Packages) {
                this.shipmentRuleForm.rows = [];
                var pack = this.form.Data.Packages[index];

                var number = 0;
                for (var it of pack.PackageShapes) {
                    number += it.Quantity;
                }
                var total = 0;
                if (pack.Quantity) {
                    total = pack.Quantity * number;
                }
                total = total + 2;
           
                if (pack && pack.ShipmentIDs) {
                    for (var m = 0; m < pack.ShipmentIDs.length; m++) {
                        if (pack && pack.ShipmentIDs && pack.ShipmentIDs[m].Type == 10) {
                            var shipmentId = pack.ShipmentIDs[m].Value as string;
                            var p1 = {
                                Value: shipmentId,
                                Type: 10,
                            }
                            this.shipmentRuleForm.rows.push(p1);
                        }

                        if (pack && pack.ShipmentIDs && pack.ShipmentIDs[m].Type == 11) {
                            var referenceId = pack.ShipmentIDs[m].Value as string;
                            var p2 = {
                                Value: referenceId,
                                Type: 11,
                            }
                            this.shipmentRuleForm.rows.push(p2);
                        }
                    }
                }

                if (this.shipmentRuleForm && this.shipmentRuleForm.rows) {
                    var shipmentIdExit = false;
                    var referenceIdExit = false;
                    for (var row of this.shipmentRuleForm.rows) {
                        if (row.Type == 10) {
                            shipmentIdExit = true;
                        }
                        if (row.Type == 11) {
                            referenceIdExit = true;
                        }
                    }
                    if (shipmentIdExit == false) {
                        var p10 = {
                            Value: "",
                            Type: 10,
                        }
                        this.shipmentRuleForm.rows.push(p10);
                    }
                    if (referenceIdExit == false) {
                        var p20 = {
                            Value: "",
                            Type: 11,
                        }
                        this.shipmentRuleForm.rows.push(p20);
                    }

                }



                this.shipmentRuleForm.number = total;
                if (this.form.Data.Packages[index].ShipmentIDs == undefined) {
                    this.form.Data.Packages[index].ShipmentIDs = [];
                }
                for (var i = 0; i < total; i++) {
                    var value = "";
                    if (pack && pack.ShipmentIDs) {
                        if (i >= (pack.ShipmentIDs.length + 2)) {
                            value = "";
                            var p30 = {
                                Value: value,
                                Type: 20,
                            };
                            this.shipmentRuleForm.rows.push(p30);
                        }
                        else if (i >= 2) {
                            if (pack.ShipmentIDs[i].Type == 20) {
                                value = pack.ShipmentIDs[i].Value as string;
                                var p = {
                                    Value: value,
                                    Type: 20,
                                };
                                this.shipmentRuleForm.rows.push(p);
                            }
                        }
                        

                    }
                   
                }
                this.shipmentRuleForm.visiable = true;
            }
        }
        onImage(row: any): void {
            this.imageRuleForm.row = row;
            this.imageRuleForm.visiable = true;
        }

        handleBack(): void {
            var url = this.$route.query.backUrl as string;
            if (
                url != null &&
                typeof url != "undefined" &&
                url != "undefined" &&
                url != ""
            ) {
                this.$router.push(url);
            } else {
                history.go(-1);
            }
        }

        /**查询表单数据 */
        handleGet(): void {
            this.isLoading = true;

            ParamCurrencySystemAppService.GetSelect().then((currency) => {
                if (currency.data.Data) this.currencys = currency.data.Data;
                for (var cur of this.currencys) {
                    if (cur.Label && cur.Value) {
                        if (cur.Label?.indexOf("USD") >= 0 || cur.Label?.indexOf("美元") >= 0) {
                            this.form.Data.CurrencySystemId = cur.Value;
                            this.form.Data.CurrencySystemName = cur.Data.Code;
                            break;
                        }
                    }
                }

                if (this.id) {
                    OrderWarehouseAppService.GetById(this.id).then((r) => {
                        if (r.data.Data) this.form.Data = r.data.Data;
                        if (this.form.Data.Type) {
                            this.type = this.form.Data.Type.toString();
                        }
                        if (this.form.Data && this.form.Data.Packages && this.form.Data.Packages.length > 0) {
                            for (var pk of this.form.Data.Packages) {
                                pk.PackageShapes = pk.PackageShares;
                            }
                        }

                        this.form.Data.CurrencySystemName = "";
                        for (var currency of this.currencys) {
                            if (currency.Value == this.form.Data.CurrencySystemId) {
                                this.form.Data.CurrencySystemName = currency.Data.Code;
                            }
                        }


                        this.formTemp = JSON.parse(JSON.stringify(this.form));//这个在前，以免部分原始数据在计算后被覆盖
                        this.onCountPackage();//这个在后，以免部分原始数据在计算后被覆盖
                        if (this.formTemp.Data && this.formTemp.Data.Packages && this.formTemp.Data.Packages.length > 0 && this.form.Data && this.form.Data.Packages && this.form.Data.Packages.length > 0) {
                            for (var i = 0; i < this.formTemp.Data.Packages.length; i++) {
                                var pak = this.formTemp.Data.Packages[i];
                                if (pak.Volume != null && pak.Volume != undefined) {
                                    this.form.Data.Packages[i].Volume = pak.Volume;
                                }
                                if (pak.GrossWeight != null && pak.GrossWeight != undefined) {
                                    this.form.Data.Packages[i].GrossWeight = pak.GrossWeight;
                                }
                            }
                        }
                        var historyParam = {
                            Type: Number(this.type),
                        };
                        OrderWarehouseAppService.GetPartnerHistory(historyParam).then((hi) => {
                            if (hi.data.Data) this.partners = hi.data.Data;
                            this.isLoading = false;
                            if (this.form.Data.Partner && this.form.Data.Partner != "") {
                                this.handleSelectPartner(this.form.Data.Partner);
                            }
                        })

                        if (this.bottomButtons) {
                            this.bottomButtons[3].Hide = false;
                        }
                    });
                }
                else {
                    var historyParam = {
                        Type: Number(this.type),
                    };
                    
                    OrderWarehouseAppService.GetPartnerHistory(historyParam).then((hi) => {
                        if (hi.data.Data) this.partners = hi.data.Data;
                        if (this.bottomButtons) {
                            this.bottomButtons[2].Hide = false;
                        }
                        this.isLoading = false;
                    })
                }
            });
        }
        onChangeLength(index: number): void {
            var result = "";
            if (
                this.shapeRuleForm.Data &&
                this.shapeRuleForm.Data.Items &&
                this.shapeRuleForm.Data.Items[index].Length != undefined
            ) {
                result = setDecimal(
                    this.shapeRuleForm.Data.Items[index].Length.toString(),
                    2,
                    false
                );
                if (result != "") {
                    this.shapeRuleForm.Data.Items[index].Length = Number(result);
                } else {
                    this.shapeRuleForm.Data.Items[index].Length = undefined;
                }

                if (
                    this.shapeRuleForm.Data.Items[index].Length &&
                    this.shapeRuleForm.Data.Items[index].Width &&
                    this.shapeRuleForm.Data.Items[index].Height
                ) {
                    this.shapeRuleForm.Data.Items[index].Volume = (this.shapeRuleForm.Data.Items[index].Length * this.shapeRuleForm.Data.Items[index].Width * this.shapeRuleForm.Data.Items[index].Height) / 1000000;
                    var r1 = setDecimal(
                        this.shapeRuleForm.Data.Items[index].Volume.toString(),
                        4,
                        false
                    );
                    if (r1 != "") {
                        this.shapeRuleForm.Data.Items[index].Volume = Number(r1);
                    } else {
                        this.shapeRuleForm.Data.Items[index].Volume = undefined;
                    }
                }
            }
        }
        onChangeWidth(index: number): void {
            var result = "";
            if (
                this.shapeRuleForm.Data &&
                this.shapeRuleForm.Data.Items &&
                this.shapeRuleForm.Data.Items[index].Width != undefined
            ) {
                result = setDecimal(
                    this.shapeRuleForm.Data.Items[index].Width.toString(),
                    2,
                    false
                );
                if (result != "") {
                    this.shapeRuleForm.Data.Items[index].Width = Number(result);
                } else {
                    this.shapeRuleForm.Data.Items[index].Width = undefined;
                }

                if (
                    this.shapeRuleForm.Data.Items[index].Length &&
                    this.shapeRuleForm.Data.Items[index].Width &&
                    this.shapeRuleForm.Data.Items[index].Height
                ) {
                    this.shapeRuleForm.Data.Items[index].Volume =
                        (this.shapeRuleForm.Data.Items[index].Length *
                            this.shapeRuleForm.Data.Items[index].Width *
                            this.shapeRuleForm.Data.Items[index].Height) /
                        1000000;
                    var r1 = setDecimal(
                        this.shapeRuleForm.Data.Items[index].Volume.toString(),
                        4,
                        false
                    );
                    if (r1 != "") {
                        this.shapeRuleForm.Data.Items[index].Volume = Number(r1);
                    } else {
                        this.shapeRuleForm.Data.Items[index].Volume = undefined;
                    }
                }
            }
        }
        onChangeHeight(index: number): void {
            var result = "";
            if (
                this.shapeRuleForm.Data &&
                this.shapeRuleForm.Data.Items &&
                this.shapeRuleForm.Data.Items[index].Height != undefined
            ) {
                result = setDecimal(
                    this.shapeRuleForm.Data.Items[index].Height.toString(),
                    2,
                    false
                );
                if (result != "") {
                    this.shapeRuleForm.Data.Items[index].Height = Number(result);
                } else {
                    this.shapeRuleForm.Data.Items[index].Height = undefined;
                }

                if (
                    this.shapeRuleForm.Data.Items[index].Length &&
                    this.shapeRuleForm.Data.Items[index].Width &&
                    this.shapeRuleForm.Data.Items[index].Height
                ) {
                    this.shapeRuleForm.Data.Items[index].Volume =
                        (this.shapeRuleForm.Data.Items[index].Length *
                            this.shapeRuleForm.Data.Items[index].Width *
                            this.shapeRuleForm.Data.Items[index].Height) /
                        1000000;
                    var r1 = setDecimal(
                        this.shapeRuleForm.Data.Items[index].Volume.toString(),
                        4,
                        false
                    );
                    if (r1 != "") {
                        this.shapeRuleForm.Data.Items[index].Volume = Number(r1);
                    } else {
                        this.shapeRuleForm.Data.Items[index].Volume = undefined;
                    }
                }
            }
        }
        onChangePackageGrossWeight(index: number): void {
            var result = "";
            if (
                this.form.Data &&
                this.form.Data.Packages &&
                this.form.Data.Packages[index] &&
                this.form.Data.Packages[index].GrossWeight
            ) {
                var grossWeight = this.form.Data.Packages[index]?.GrossWeight;
                if (grossWeight) {
                    result = setDecimal(grossWeight + "", 2, false);
                    if (result != "") {
                        this.form.Data.Packages[index].GrossWeight = Number(result);
                    } else {
                        this.form.Data.Packages[index].GrossWeight = undefined;
                    }
                }
            }
        }
        onChangePackageQuantity(index: number): void {
            var result = "";
            if (
                this.form.Data &&
                this.form.Data.Packages &&
                this.form.Data.Packages[index] &&
                this.form.Data.Packages[index].Quantity
            ) {
                var quantity = this.form.Data.Packages[index]?.Quantity;
                if (quantity) {
                    result = setDecimal(quantity + "", 0, false);
                    if (result != "") {
                        this.form.Data.Packages[index].Quantity = Number(result);
                    } else {
                        this.form.Data.Packages[index].Quantity = undefined;
                    }
                }
                if (this.form.Data.Packages[index].Quantity) {
                    var number1 = 0;
                    var number2 = 0;
                    var pk = this.form.Data.Packages[index];
                    if (pk.PackageShapes && pk.PackageShapes.length > 0) {
                        for (var shape of pk.PackageShapes) {
                            if (shape.Quantity && pk.Quantity) {
                                number1 += shape.Quantity * pk.Quantity;
                            }
                        }
                    }

                    if (this.formTemp && this.formTemp.Data && this.formTemp.Data.Packages && this.formTemp.Data.Packages.length > 0) {
                        var t2 = this.formTemp.Data.Packages[index];
                        if (t2.Quantity && t2.PackageShapes) {
                            for (var it2 of t2.PackageShapes) {
                                if (it2.Quantity) {
                                    number2 += it2.Quantity * t2.Quantity;
                                }
                            }
                        }
                    }
                    if (number1 != number2 && pk.IsShipment == true) {

                        ElMessageBox.confirm("更改包装数量将重置Reference ID，是否继续？", "提示", {
                            confirmButtonText: "确定",
                            cancelButtonText: "关闭",
                            type: "warning",
                        })
                            .then(() => {


                                if (this.form.Data && this.form.Data.Packages && this.form.Data.Packages.length > 0) {
                                    var pk = this.form.Data.Packages[index];
                                    if (pk.ShipmentIDs && pk.ShipmentIDs.length > 0) {
                                        var shipmentId = "";
                                        var referenceId = "";
                                        for (var id of pk.ShipmentIDs) {
                                            if (id.Value) {
                                                if (id.Type == 10) {
                                                    shipmentId = id.Value;
                                                }
                                                else if (id.Type == 11) {
                                                    referenceId = id.Value;
                                                }
                                            }
                                        }
                                        pk.ShipmentIDs = [];
                                        var number = 0;
                                        if (pk.PackageShapes) {
                                            for (var shape of pk.PackageShapes) {
                                                if (shape.Quantity && pk.Quantity) {
                                                    number += shape.Quantity * pk.Quantity;
                                                }

                                            }
                                        }
                                        pk.ShipmentIDs.push({
                                            Type: 10,
                                            Value: shipmentId,
                                        });
                                        pk.ShipmentIDs.push({
                                            Type: 11,
                                            Value: referenceId,
                                        });
                                        for (var i = 0; i < number; i++) {
                                            pk.ShipmentIDs.push({
                                                Type: 20,
                                                Value: "",
                                            });
                                        }
                                    }
                                }
                                this.onCountPackage();
                                this.formTemp = JSON.parse(JSON.stringify(this.form));

                            })
                            .catch(() => {
                                if (this.form && this.form.Data && this.form.Data.Packages && this.form.Data.Packages.length
                                    && this.formTemp && this.formTemp.Data && this.formTemp.Data.Packages && this.formTemp.Data.Packages.length > 0) {
                                    this.form.Data.Packages[index].Quantity = this.formTemp.Data.Packages[index].Quantity;
                                }

                            });
                    }
                    else {
                        this.formTemp = JSON.parse(JSON.stringify(this.form));
                    }
                }
            }

            if (this.form.Data &&
                this.form.Data.Packages &&
                this.form.Data.Packages[index] && (this.form.Data.Packages[index].Quantity == null || this.form.Data.Packages[index].Quantity == undefined)) {
                this.form.Data.Packages[index].DeliveryQuantity = undefined;
            }
        }
        onChangePackageDeliveryQuantity(index: number): void {
            var result = "";
            if (
                this.form.Data &&
                this.form.Data.Packages &&
                this.form.Data.Packages[index] &&
                this.form.Data.Packages[index].DeliveryQuantity
            ) {
                var DeliveryQuantity = this.form.Data.Packages[index]?.DeliveryQuantity;
                if (DeliveryQuantity) {
                    result = setDecimal(DeliveryQuantity + "", 0, true);
                    if (result != "") {
                        this.form.Data.Packages[index].DeliveryQuantity = Number(result);
                    } else {
                        this.form.Data.Packages[index].DeliveryQuantity = undefined;
                    }
                }
            }

            if (this.form.Data && this.form.Data.Packages && this.form.Data.Packages[index].Quantity && this.form.Data.Packages[index].DeliveryQuantity) {
                var DeliveryQuantity1 = this.form.Data.Packages[index].DeliveryQuantity;
                var Quantity = this.form.Data.Packages[index].Quantity;
                if (DeliveryQuantity1 && Quantity) {
                    if (DeliveryQuantity1 > Quantity) {
                        this.form.Data.Packages[index].DeliveryQuantity = this.form.Data.Packages[index].Quantity;
                    }
                }
            }
        }
        onChangePackageVolume(index: number): void {
            var result = "";
            if (
                this.form.Data &&
                this.form.Data.Packages &&
                this.form.Data.Packages[index] &&
                this.form.Data.Packages[index].Volume
            ) {
                var volume = this.form.Data.Packages[index]?.Volume;
                if (volume) {
                    result = setDecimal(volume + "", 4, false);
                    if (result != "") {
                        this.form.Data.Packages[index].Volume = Number(result);
                    } else {
                        this.form.Data.Packages[index].Volume = undefined;
                    }
                }
            }
        }

        onChangeGrossWeight(index: number): void {
            var result = "";
            if (
                this.shapeRuleForm.Data &&
                this.shapeRuleForm.Data.Items &&
                this.shapeRuleForm.Data.Items[index].GrossWeight != undefined
            ) {
                result = setDecimal(
                    this.shapeRuleForm.Data.Items[index].GrossWeight.toString(),
                    2,
                    false
                );
                if (result != "") {
                    this.shapeRuleForm.Data.Items[index].GrossWeight = Number(result);
                } else {
                    this.shapeRuleForm.Data.Items[index].GrossWeight = undefined;
                }
            }
        }
        onChangeVolume(index: number): void {
            var result = "";
            if (
                this.shapeRuleForm.Data &&
                this.shapeRuleForm.Data.Items &&
                this.shapeRuleForm.Data.Items[index].Volume != undefined
            ) {
                result = setDecimal(
                    this.shapeRuleForm.Data.Items[index].Volume.toString(),
                    4,
                    false
                );
                if (result != "") {
                    this.shapeRuleForm.Data.Items[index].Volume = Number(result);
                } else {
                    this.shapeRuleForm.Data.Items[index].Volume = undefined;
                }
            }
        }
        onChangeQuantity(index: number): void {
            var result = "";
            if (
                this.shapeRuleForm.Data &&
                this.shapeRuleForm.Data.Items &&
                this.shapeRuleForm.Data.Items[index].Quantity != undefined
            ) {
                result = setDecimal(
                    this.shapeRuleForm.Data.Items[index].Quantity.toString(),
                    0,
                    false
                );
                if (result != "") {
                    this.shapeRuleForm.Data.Items[index].Quantity = Number(result);
                } else {
                    this.shapeRuleForm.Data.Items[index].Quantity = undefined;
                }
            }
        }

        onChangeSpecQuantity(index: number): void {
            var result = "";
            if (
                this.specRuleForm.Data &&
                this.specRuleForm.Data.Items &&
                this.specRuleForm.Data.Items[index] &&
                this.specRuleForm.Data.Items[index].Quantity
            ) {
                var quantity = this.specRuleForm.Data.Items[index]?.Quantity;
                if (quantity) {
                    result = setDecimal(quantity + "", 2, false);
                    if (result != "") {
                        this.specRuleForm.Data.Items[index].Quantity = Number(result);
                    } else {
                        this.specRuleForm.Data.Items[index].Quantity = undefined;
                    }
                }

                if (
                    this.specRuleForm.Data &&
                    this.specRuleForm.Data.Items &&
                    this.specRuleForm.Data.Items[index] &&
                    this.specRuleForm.Data.Items[index].UnitPrice
                ) {
                    var totalPrice =
                        this.specRuleForm.Data.Items[index].UnitPrice *
                        this.specRuleForm.Data.Items[index].Quantity;
                    result = setDecimal(totalPrice + "", 2, false);
                    if (result != "") {
                        this.specRuleForm.Data.Items[index].TotalPrice = Number(result);
                    } else {
                        this.specRuleForm.Data.Items[index].TotalPrice = undefined;
                    }
                }
            }
        }

        onChangeSpecUnitPrice(index: number): void {
            var result = "";
            if (
                this.specRuleForm.Data &&
                this.specRuleForm.Data.Items &&
                this.specRuleForm.Data.Items[index] &&
                this.specRuleForm.Data.Items[index].UnitPrice
            ) {
                var UnitPrice = this.specRuleForm.Data.Items[index]?.UnitPrice;
                if (UnitPrice) {
                    result = setDecimal(UnitPrice + "", 2, true);
                    if (result != "") {
                        this.specRuleForm.Data.Items[index].UnitPrice = Number(result);
                    } else {
                        this.specRuleForm.Data.Items[index].UnitPrice = undefined;
                    }
                }

                if (
                    this.specRuleForm.Data &&
                    this.specRuleForm.Data.Items &&
                    this.specRuleForm.Data.Items[index] &&
                    this.specRuleForm.Data.Items[index].Quantity
                ) {
                    var totalPrice =
                        this.specRuleForm.Data.Items[index].UnitPrice *
                        this.specRuleForm.Data.Items[index].Quantity;
                    result = setDecimal(totalPrice + "", 2, true);
                    if (result != "") {
                        this.specRuleForm.Data.Items[index].TotalPrice = Number(result);
                    } else {
                        this.specRuleForm.Data.Items[index].TotalPrice = undefined;
                    }
                }
            }
        }

        onChangeSpecTotalPrice(index: number): void {
            var result = "";
            if (
                this.specRuleForm.Data &&
                this.specRuleForm.Data.Items &&
                this.specRuleForm.Data.Items[index] &&
                this.specRuleForm.Data.Items[index].TotalPrice
            ) {
                var TotalPrice = this.specRuleForm.Data.Items[index]?.TotalPrice;
                if (TotalPrice) {
                    result = setDecimal(TotalPrice + "", 2, true);
                    if (result != "") {
                        this.specRuleForm.Data.Items[index].TotalPrice = Number(result);
                    } else {
                        this.specRuleForm.Data.Items[index].TotalPrice = undefined;
                    }
                }

                if (
                    this.specRuleForm.Data &&
                    this.specRuleForm.Data.Items &&
                    this.specRuleForm.Data.Items[index] &&
                    this.specRuleForm.Data.Items[index].Quantity
                ) {
                    var unitPrice =
                        this.specRuleForm.Data.Items[index].TotalPrice /
                        this.specRuleForm.Data.Items[index].Quantity;
                    result = setDecimal(unitPrice + "", 2, true);
                    if (result != "") {
                        this.specRuleForm.Data.Items[index].UnitPrice = Number(result);
                    } else {
                        this.specRuleForm.Data.Items[index].UnitPrice = undefined;
                    }
                }
            }
        }
        onSpec(index: number): void {
            this.specIndex = index;
            this.specRuleForm.Data.ChargeUnit = "";
            if (
                this.form.Data &&
                this.form.Data.Packages &&
                this.form.Data.Packages.length > 0 &&
                this.form.Data.Packages[index].PackageSpecs
            ) {

                this.specRuleForm.Data.Items = [];
                var t = this.form.Data.Packages[index];
                if (t.PackageSpecs && t.PackageSpecs.length > 0) {
                    var chargeUnit = t.PackageSpecs[0].Spec.Product.ChargeUnithEn;
                    var count = 0;

                    var shapeCount = 0;
                    if (
                        this.form.Data &&
                        this.form.Data.Packages &&
                        this.form.Data.Packages[index] &&
                        this.form.Data.Packages[index].PackageShapes
                    ) {
                        shapeCount = this.form.Data.Packages[index].PackageShapes.length;
                    }
                    for (var it of t.PackageSpecs) {
                        var row = {
                            NameZh:
                                it.NameZh == null || it.NameZh == undefined || it.NameZh == ""
                                    ? it.Spec.Product.NameZh
                                    : it.NameZh,
                            NameEn:
                                it.NameEn == null || it.NameEn == undefined || it.NameEn == ""
                                    ? it.Spec.Product.NameEn
                                    : it.NameEn,
                            Quantity: it.Quantity,
                            Spec: it.Spec,
                            SpecId:
                                it.NameEn == null || it.NameEn == undefined || it.NameEn == ""
                                    ? it.Spec.Id
                                    : it.SpecId,
                            UnitPrice: it.UnitPrice,
                            TotalPrice: it.TotalPrice,
                            ShapeCount: shapeCount,
                            En: [],
                            Zh: [],
                            Images: [""],
                            FirstImage: "",
                            ChargeUnithEn: it.ChargeUnithEn == null || it.ChargeUnithEn == undefined || it.ChargeUnithEn == "" ? it.Spec.Product.ChargeUnithEn : it.ChargeUnithEn,
                        };
                        if (shapeCount > 1) {
                            row.Quantity = 1;
                        }

                        if (
                            row.Spec != null &&
                            row.Spec != undefined &&
                            row.Spec.SpecImages != null &&
                            row.Spec.SpecImages != undefined &&
                            row.Spec.SpecImages.length > 0
                        ) {
                            row.FirstImage = this.client?.signatureUrl(
                                row.Spec.SpecImages[0].Url
                            ) as string;
                            row.Images = [];
                            for (var img of row.Spec.SpecImages) {
                                var url = this.client?.signatureUrl(img.Url) as string;
                                row.Images.push(url);
                            }
                        }

                        this.specRuleForm.Data.Items.push(row);

                        if (chargeUnit != it.Spec.Product.ChargeUnithEn) {
                            count++;
                        }
                    }
                    if (count > 0) {
                        chargeUnit = "PCS";
                    }
                    this.specRuleForm.Data.ChargeUnit = chargeUnit;
                }
            }
            if (this.specRuleForm.Data && this.specRuleForm.Data.Items) {
                var productIds = [];
                for (var spec of this.specRuleForm.Data.Items) {
                    productIds.push(spec.Spec.ProductId);
                }
                var param = {
                    Ids: productIds,
                };
                ProductAppService.GetAllAlias(param).then((alias) => {
                    for (var pro of this.specRuleForm.Data.Items) {
                        pro.Zh = [];
                        pro.En = [];
                        if (alias.data.Data) {
                            for (var d of alias.data.Data) {
                                if (d.ProductId == pro.Spec.ProductId) {
                                    if (d.Type == 0) {
                                        var o = {
                                            Name: d.Name,
                                        };
                                        pro.Zh.push(o);
                                    } else if (d.Type == 1) {
                                        var o1 = {
                                            Name: d.Name,
                                        };
                                        pro.En.push(o1);
                                    }
                                }
                            }
                        }
                    }
                    console.log(this.specRuleForm.Data);
                    this.specRuleForm.Data.visible = true;
                });
            }
        }
        onShape(index: number): void {
            this.packageIndex = index;
            if (this.form.Data.Packages && this.form.Data.Packages.length > 0) {
                if (this.shapeRuleForm.Data.Items == undefined) {
                    this.shapeRuleForm.Data.Items = [];
                }
                if (this.form.Data.Packages[index].PackageShapes != undefined) {
                    this.shapeRuleForm.Data.Items = JSON.parse(
                        JSON.stringify(this.form.Data.Packages[index].PackageShapes)
                    );
                }
                if (this.shapeRuleForm.Data && this.shapeRuleForm.Data.Items) {
                    var count = 0;
                    for (var it of this.shapeRuleForm.Data.Items) {
                        this.onChangeLength(count);
                        this.onChangeWidth(count);
                        this.onChangeHeight(count);
                        this.onChangeGrossWeight(count);
                        this.onChangeVolume(count);
                        count++;
                    }
                }
                this.shapeRuleForm.Data.visible = true;
            }

            this.shapeRuleForm.Rules.Items_Quantity = [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "blur",
                },
            ];
        }
        onTrimSpace(): void {
            if (this.form.Data) {
                if (this.form.Data.Code) {
                    this.form.Data.Code = TrimSpace(this.form.Data.Code as string);
                }
            }
        }
        onDeletePackage(index: number): void {
            if (this.form.Data && this.form.Data.Packages) {
                this.form.Data.Packages.splice(index, 1);
            }
        }
        onAddPackage(): void {
            const CustomsPackage = this.$refs["CustomsPackage"] as any;
            CustomsPackage.onInit();
        }
        onSelectProductPackage(rows: any): void {
            if (this.form.Data && this.form.Data.Packages) {
                for (var row of rows) {
                    row.PackageId = row.Id;
                    row.IsShipment = false;//表示是否已录入ShipmentID
                    if (row.PackageSpecs && row.PackageSpecs.length > 0) {
                        for (var spec of row.PackageSpecs) {
                            if (spec.NameZh == null || spec.NameZh == undefined || spec.NameZh == "") {
                                if (spec.Spec != null && spec.Spec.Product) {
                                    spec.NameZh = spec.Spec.Product.NameZh;
                                    spec.NameEn = spec.Spec.Product.NameEn;
                                }
                            }

                        }

                    }
                    this.form.Data.Packages.push(row);
                }
                this.onCountPackage();
            }

            this.formTemp = JSON.parse(JSON.stringify(this.form));
        }

        //提示包装数量改变，是否需要重置Reference ID
        //1表示包装数量有变化，需要提示才能继续操作
        //2表示Reference ID还未录入或者数量未变化，不需要提示，也不需要重置。
        onChangePackageNumber(index: number): number {
            var IsShipment = false;
            var t;
            if (this.form.Data && this.form.Data.Packages && this.form.Data.Packages.length > 0) {
                IsShipment = this.form.Data.Packages[index].IsShipment;
                t = this.form.Data.Packages[index];
            }
            if (this.shapeRuleForm.Data && this.shapeRuleForm.Data.Items && this.shapeRuleForm.Data.Items.length > 0) {
                var t1 = this.shapeRuleForm.Data.Items;
                if (IsShipment == true) {
                    var number1 = 0;
                    var number2 = 0;
                    if (t && t.Quantity) {
                        for (var it of t1) {
                            if (it.Quantity) {
                                number1 += it.Quantity * t.Quantity;
                            }
                        }
                    }

                    if (this.formTemp && this.formTemp.Data && this.formTemp.Data.Packages && this.formTemp.Data.Packages.length > 0) {
                        var t2 = this.formTemp.Data.Packages[index];
                        if (t2.Quantity && t2.PackageShapes) {
                            for (var it2 of t2.PackageShapes) {
                                if (it2.Quantity) {
                                    number2 += it2.Quantity * t2.Quantity;
                                }
                            }
                        }
                    }
                    if (number1 != number2) {
                        return 1;
                    }
                    else {
                        return 2;
                    }
                }
                else {
                    return 2;
                }

            }
            else {
                return 2;
            }

        }
        onCountPackage(): void {
            if (this.form.Data && this.form.Data.Packages) {
                for (var pack of this.form.Data.Packages) {
                    pack.SizeQuantity = "";
                    pack.IsVolume = true;
                    pack.IsGrossWeight = true;
                    pack.ShipmentId = "暂无数据";
                    pack.IsShipment = false;
                    if (pack.ShipmentIDs && pack.ShipmentIDs.length > 0) {
                        for (var sp of pack.ShipmentIDs) {
                            if (sp.Value && sp.Value != "") {
                                pack.IsShipment = true;
                                break;
                            }
                        }
                    }
                    if (pack.PackageShapes && pack.Quantity) {
                        var isVolume = true;
                        var isGrossWeight = true;
                        var volume = 0;
                        var grossWeight = 0;
                        for (var it of pack.PackageShapes) {
                            if (it.GrossWeight == null || it.GrossWeight == undefined) {
                                isGrossWeight = false;
                            }
                            else {
                                if (it.GrossWeight) {
                                    grossWeight += it.GrossWeight;// * it.Quantity;
                                }
                            }
                            if (it.Volume == null || it.Volume == undefined) {
                                isVolume = false;
                            } else {
                                if (it.Volume) {
                                    volume += it.Volume;// * it.Quantity;
                                }
                            }
                            if (
                                it.Length != null &&
                                it.Length != undefined &&
                                it.Length != 0 &&
                                it.Width != null &&
                                it.Width != undefined &&
                                it.Width != 0 &&
                                it.Height != null &&
                                it.Height != undefined &&
                                it.Height != 0
                            ) {
                                pack.SizeQuantity +=
                                    "【" +
                                    it.Length +
                                    "cm*" +
                                    it.Width +
                                    "cm*" +
                                    it.Height +
                                    "cm*" +
                                    it.Quantity +
                                    "】";
                            } else {
                                pack.SizeQuantity += "【暂无数据*" + it.Quantity + "】";
                            }
                        }
                        pack.IsVolume = isVolume;
                        pack.IsGrossWeight = isGrossWeight;
                        var result = "";
                        if (isVolume == true) {
                            //volume = volume * pack.Quantity;
                            result = setDecimal(volume.toString(), 4, false);
                            if (result != "") {
                                pack.Volume = Number(result);
                            } else {
                                pack.Volume = undefined;
                            }
                        } else {
                            pack.Volume = undefined;
                        }
                        if (isGrossWeight == true) {
                            //grossWeight = grossWeight * pack.Quantity;
                            result = setDecimal(grossWeight.toString(), 2, false);
                            if (result != "") {
                                pack.GrossWeight = Number(result);
                            } else {
                                pack.GrossWeight = undefined;
                            }
                        } else {
                            pack.GrossWeight = undefined;
                        }
                    }
                    if (pack.ShipmentIDs && pack.ShipmentIDs.length > 0) {
                        for (var shipment of pack.ShipmentIDs) {
                            if (shipment.Type == 10) {
                                pack.ShipmentId = shipment.Value;
                            }
                        }
                    }
                }
            }
        }

        /**按钮点击 */
        handleClick(btn: MiezzButton, complete: () => void): void {
            if (this.form.Data) {
                this.form.Data.Type = Number(this.type);
                if (this.form.Data.Packages) {
                    for (var it of this.form.Data.Packages) {
                        it.PackageShares = it.PackageShapes;
                    }
                }
            }
            switch (btn.Code) {
                case "submit":
                    if (this.form.Data.Packages == null || this.form.Data.Packages == undefined || this.form.Data.Packages.length <= 0) {
                        ElMessage.warning("请至少添加一个货物");
                        return;
                    }
                    this.form.Data.PartnerId = undefined;
                    if (this.form.Data.Partner != undefined && this.form.Data.Partner != null && this.form.Data.Partner != "" && this.partners) {
                        for (var partner of this.partners) {
                            if (partner.Label == this.form.Data.Partner) {
                                this.form.Data.PartnerId = partner.Value;
                            }
                        }
                    }
                    MiezzForm.submit(this.$refs, "ruleForm", (v) => {
                        MiezzForm.submit(this.$refs, "ruleFormPackage", (vCustoms) => {
                            if (v == true && vCustoms) {
                                OrderWarehouseAppService.Post(this.form.Data)
                                    .then(() => {
                                        this.handleSubmit();
                                        this.handleBack();
                                        ElMessage.success("保存成功");
                                        complete();
                                    })
                                    .catch(complete);
                            } else {
                                complete();
                            }
                        });
                    });
                    break;
                case "continue":
                    if (this.form.Data.Packages == null || this.form.Data.Packages == undefined || this.form.Data.Packages.length <= 0) {
                        ElMessage.warning("请至少添加一个货物");
                        return;
                    }
                    MiezzForm.submit(this.$refs, "ruleForm", (v) => {
                        MiezzForm.submit(this.$refs, "ruleFormPackage", (vCustoms) => {
                            if (v == true && vCustoms) {
                                OrderWarehouseAppService.Post(this.form.Data)
                                    .then(() => {
                                        this.handleSubmit();
                                        ElMessage.success("保存成功");
                                        this.form.Data = new DtoFormOrderWarehouse();
                                        MiezzForm.resetFields(this.$refs, "ruleForm");
                                        complete();
                                    })
                                    .catch(complete);
                            } else {
                                complete();
                            }
                        });
                    });
                    break;
                case "delivery":
                    if (this.form && this.form.Data && this.form.Data.Packages && this.form.Data.Packages.length > 0) {
                        for (var pk of this.form.Data.Packages) {
                            pk.DeliveryQuantity = pk.Quantity;
                        }
                    }
                    complete();
                    break;
                case "back":
                    this.handleBack();
                    complete();
                    break;
                default:
                    break;
            }
        }

        /**表单提交-回调 */
        @Emit()
        handleSubmit(): void {
            //
        }

        handleClickShape(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "submit":
                    var result = this.onChangePackageNumber(this.packageIndex);
                    if (result == 1) {

                        ElMessageBox.confirm("更改包装数量将重置Reference ID，是否继续？", "提示", {
                            confirmButtonText: "确定",
                            cancelButtonText: "关闭",
                            type: "warning",
                        })
                            .then(() => {


                                MiezzForm.submit(this.$refs, "ruleFormShape", (v) => {
                                    if (v == true) {
                                        if (
                                            this.form.Data &&
                                            this.form.Data.Packages &&
                                            this.shapeRuleForm.Data &&
                                            this.shapeRuleForm.Data.Items
                                        ) {
                                            this.form.Data.Packages[this.packageIndex].PackageShapes = JSON.parse(JSON.stringify(this.shapeRuleForm.Data.Items));
                                        }
                                        if (this.form.Data && this.form.Data.Packages && this.form.Data.Packages.length > 0) {
                                            var pk = this.form.Data.Packages[this.packageIndex];
                                            if (pk.ShipmentIDs && pk.ShipmentIDs.length > 0) {
                                                var shipmentId = "";
                                                for (var id of pk.ShipmentIDs) {
                                                    if (id.Type == 10 && id.Value) {
                                                        shipmentId = id.Value;
                                                    }
                                                }
                                                pk.ShipmentIDs = [];
                                                var number = 0;
                                                if (pk.PackageShapes) {
                                                    for (var shape of pk.PackageShapes) {
                                                        if (shape.Quantity && pk.Quantity) {
                                                            number += shape.Quantity * pk.Quantity;
                                                        }

                                                    }
                                                }
                                                pk.ShipmentIDs.push({
                                                    Type: 10,
                                                    Value: shipmentId,
                                                });
                                                for (var i = 0; i < number; i++) {
                                                    pk.ShipmentIDs.push({
                                                        Type: 20,
                                                        Value: "",
                                                    });
                                                }
                                            }
                                        }

                                        this.onCountPackage();



                                        this.formTemp = JSON.parse(JSON.stringify(this.form));
                                        this.packageIndex = -1;
                                        this.shapeRuleForm.Data.visible = false;
                                        complete();
                                    } else {
                                        complete();
                                    }
                                });

                            })
                            .catch(() => {

                                if (
                                    this.form.Data &&
                                    this.form.Data.Packages &&
                                    this.shapeRuleForm.Data
                                ) {
                                    //this.shapeRuleForm.Data.Items = JSON.parse(JSON.stringify(this.form.Data.Packages[this.packageIndex].PackageShapes));
                                    var index = 0;
                                    for (var it of this.shapeRuleForm.Data.Items) {
                                        it.Quantity = this.form.Data.Packages[this.packageIndex].PackageShapes[index].Quantity;
                                    }
                                }
                                complete();

                            });
                    }
                    else {
                        MiezzForm.submit(this.$refs, "ruleFormShape", (v1) => {
                            if (v1 == true) {
                                if (
                                    this.form.Data &&
                                    this.form.Data.Packages &&
                                    this.shapeRuleForm.Data &&
                                    this.shapeRuleForm.Data.Items
                                ) {
                                    this.form.Data.Packages[this.packageIndex].PackageShapes = JSON.parse(JSON.stringify(this.shapeRuleForm.Data.Items));
                                }

                                this.onCountPackage();
                                this.formTemp = JSON.parse(JSON.stringify(this.form));
                                this.packageIndex = -1;
                                this.shapeRuleForm.Data.visible = false;
                                complete();
                            }
                            else {
                                complete();
                            }
                        })
                    }

                    break;
                case "close":
                    this.packageIndex = -1;
                    this.shapeRuleForm.Data.visible = false;
                    complete();
                    break;
                default:
                    break;
            }
        }

        handleClickSpec(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "submit":
                    MiezzForm.submit(this.$refs, "ruleFormSpec", (v) => {
                        if (v == true) {
                            if (this.form.Data && this.form.Data.Packages) {
                                this.form.Data.Packages[this.specIndex].PackageSpecs = JSON.parse(
                                    JSON.stringify(this.specRuleForm.Data.Items)
                                );
                            }
                            this.specIndex = -1;
                            this.specRuleForm.Data.visible = false;
                            complete();
                        } else {
                            complete();
                        }
                    });
                    break;
                case "close":
                    this.specIndex = -1;
                    this.specRuleForm.Data.visible = false;
                    complete();
                    break;
                default:
                    break;
            }
        }

        handleClickShipment(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "submit":
                    if (this.form.Data && this.form.Data.Packages) {
                        this.form.Data.Packages[this.shipmentIndex].ShipmentIDs = JSON.parse(
                            JSON.stringify(this.shipmentRuleForm.rows)
                        );
                        this.form.Data.Packages[this.shipmentIndex].IsShipment = true;
                    }
                    this.onCountPackage();
                    this.shipmentIndex = -1;
                    this.shipmentRuleForm.visiable = false;
                    complete();
                    break;
                case "close":
                    this.onCountPackage();
                    this.shipmentIndex = -1;
                    this.shipmentRuleForm.visiable = false;
                    complete();
                    break;
                default:
                    break;
            }
        }

        handleClickImage(btn: MiezzButton, complete: () => void): void {
            switch (btn.Code) {
                case "close":
                    this.imageRuleForm.visiable = false;
                    complete();
                    break;
                default:
                    break;
            }
        }

        /*添加自定义验证 */
        onRule(): void {
            this.form.Rules.Packages_Volume = [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "blur",
                },
            ];
            this.form.Rules.Packages_GrossWeight = [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "blur",
                },
            ];
            this.form.Rules.Packages_Quantity = [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "blur",
                },
            ];
            this.form.Rules.Packages_DeliveryQuantity = [
                {
                    required: true,
                    message: "信息不能为空",
                    trigger: "blur",
                },
                {
                    required: true,
                    validator: (rule: any, value: string, callback: any) => {
                        var number = Number(value);
                        var fields = rule.field.replace("[", ".").replace("]", ".").split(".");
                        var index = Number(fields[1]);
                        if (index >= 0 && this.form && this.form.Data && this.form.Data.Packages && this.form.Data.Packages.length > 0) {
                            var t = this.form.Data.Packages[index];
                            if (t && t.Quantity) {
                                if (number > t.Quantity) {
                                    callback("数量范围为0到" + this.form.Data.Packages[index].Quantity);
                                    this.form.Data.Packages[index].DeliveryQuantity = undefined;
                                    return false;
                                }
                            }
                        }
                        callback();
                    },
                    trigger: "blur",
                },
            ];
        }

        isString(str: string): string {
            if (str != null && str != undefined) {
                str = str + "";
            }
            if (str == null || str == undefined || str == "") {
                return "暂无数据";
            }
            else {
                return str;
            }
        }

        /**获取英文计价单位 */
        getChargeUnitEns(q: string, cb: any): void {
            const names: string[] = [];
            for (const unit of this.chargeUnits) {
                if (unit.Data.NameEn && !names.contains(unit.Data.NameEn)) {
                    names.push(unit.Data.NameEn);
                }
            }
            const data = (q ? names.filter((it) => it.contains(q)) : names).map(
                (it) => {
                    return {
                        NameEn: it,
                    };
                }
            );
            cb(data);
        }
    }

    /*去除字符串中的所有空格 */
    function TrimSpace(str: string) {
        var name = "";
        if (str != undefined && str != "") {
            for (var i = 0; i < str.length; i++) {
                if (str[i] == "　") {
                    name += " ";
                } else {
                    name += str[i];
                }
            }
            //全角空格转换成半角空格
            str = name;
            name = "";

            for (var j = 0; j < str.length; j++) {
                if (str[j] != " ") {
                    name += str[j];
                }
            }
        }
        return name;
    }
    /*仅允许输入数字，其他字符都去除 */
    /*val：输入的字符串 */
    /*num：小数点后保留几位小数 */
    /*isZero：是否允许输入0 */
    var setDecimal = function (val: string, num: number, isZero: boolean) {
        if (val == null || val == undefined || val == "") {
            return "";
        }

        if (val != null && val != "") {
            val = val.toString();
            val = val.replace(/[^\d.]/g, "");
            val = val.replace(/\.{2,}/g, ".");
            val = val.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
            val = val.replace(/^(\\-)*(\d+)\.(\d\d\d\d\d\d\d\d\d).*$/, "$1$2.$3"); //最多输入2个小数
            if (val.indexOf(".") < 0 && val != "") {
                //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
                val = parseFloat(val).toString();
            }
        }

        if (val == "" || val.toString() == "NaN") {
            val = "";
            return val;
        }

        if (isZero == true) {
            if (parseFloat(val.toString()) == 0) {
                return val;
            }
        } else {
            if (parseFloat(val.toString()) == 0) {
                return "";
            }
        }
        if (val.toString() == "NaN") {
            val = "";
            return val;
        }

        var point = "1";
        for (var i = 0; i < num; i++) {
            point += "0";
        }
        var result = Math.round((Number(val) + Number.EPSILON) * Number(point)) / Number(point) + "";
        //var result = parseFloat(val).toRound(num);
        if (isZero == false && result == "0") {
            return "";
        } else {
            return result;
        }
    };

