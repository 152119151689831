import DtoCallbackCountItem from "./DtoCallbackCountItem";
import DtoCallbackCountSaler from "./DtoCallbackCountSaler";
import DtoCallbackProfit from "./DtoCallbackProfit";
import DtoCallbackGrossProfitStat from "./DtoCallbackGrossProfitStat";
import DtoCallbackSettlement from "./DtoCallbackSettlement";

/**
 * 
 * @description 自动生成的代码,请勿修改
 */
export default class DtoCallbackCount {
    constructor() {
        //无参构造函数
        this.Item = [];
        this.Profit = [];
        this.GrossProfit = {"BadCollectionSettlement":[],"BadPaymentSettlement":[],"MaxPrice":0,"IntervalPrice":0,"MaxPoint":0,"IntervalPoint":0,"MinPrice":0,"MinPoint":0,"GrossProfits":[]};
        this.Settlements = [];
        this.PoolCount = 0;
    }

    [proName: string]: any;
    /**
     * 收款进度/付款进度
     */
    Item?: DtoCallbackCountItem[];
    /**
     * 业务员绩效
     */
    Saler?: DtoCallbackCountSaler;
    /**
     * 
     */
    Profit?: DtoCallbackProfit[];
    /**
     * 
     */
    Month?: string;
    /**
     * 合计
     */
    Total?: number;
    /**
     * 已核销利润
     */
    RealityProfit?: number;
    /**
     * 授信
     */
    Credit?: string;
    /**
     * 毛利润
     */
    GrossProfit?: DtoCallbackGrossProfitStat;
    /**
     * 参考汇率
     */
    Rate?: string;
    /**
     * 核销记录
     */
    Settlements?: DtoCallbackSettlement[];
    /**
     * 未录入账单的委托书数量
     */
    PoolCount?: number;
    /**
     * 未录入账单的委托书
     */
    PoolIds?: string[];
}

/**
 * -表单校验
 */
export const DtoCallbackCountRules = {
}
